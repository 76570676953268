<template>
	<input
		:id="id"
		type="text"
		:value="text"
		@change="change"
		@input="$emit('input', $event.target.value)"
		@keypress="isNumber($event)"
		@keyup="validateNumber($event)"
	/>
</template>

<script>

export default {
	name: 'DecimalInput',
	data() {
		return {
			text: '',
			prevValue: '',
			re: /^[0-9]+[,.]?[0-9]{0,2}$/
		};
	},
	props: [ 'id', 'value', 'msgs', 'required' ],
	mounted() {
		this.text = this.value;
	},
	watch: {
		value(v) {
			this.text = v;
		}
	},
	methods: {
		input(event) {
			this.text = event.target.value;
		},
		change(event) {
			let val = event.target.value.trim();

			if(!val && this.required) {
				event.target.value = val;
				this.text = val;
				this.$emit('change', val);
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}]);
				return;
			}

			event.target.value = val;
			this.text = val;
			this.$emit('change', val);
			this.$set(this.msgs, this.id, []);
		},
		isNumber(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if(charCode <= 31 || (charCode >= 48 && charCode <= 57) || charCode == 44 || charCode == 46) {
				return true;
			}
			else {
				evt.preventDefault();
			}
		},
		validateNumber(evt) {
			let v = evt.target.value;

			if(!v) {
				this.prevValue = '';
				return;
			}

			v = v.replace(',', '.');
			
			if(!v.match(this.re)) {
				v = this.prevValue.replace(',', '.');
			}
			
			if(v !== evt.target.value) {
				evt.target.value = v;
				this.text = v;
			}

			this.prevValue = evt.target.value;
		}
	},
	emits: ['input', 'change']
}
</script>