/**
 * @returns {BriefingGroup[]}
 */
const getGroups = () => {
  try {
    const groups = JSON.parse(window.consentedStorage.getItem('briefingGroups')) || {};
    if(!groups.updatedAt) {
      window.consentedStorage.removeItem('briefingGroups');
      return [];
    }
    const diff = (new Date().getTime() - groups.updatedAt) / 1000 / 60;
    if(diff > 60) { // delete briefing report older than 60 minutes
      window.consentedStorage.removeItem('briefingGroups');
      return [];
    }

    return groups.groups
  } catch (error) {
    return [];
  }
}

/**
 * @param {string} groupKey
 * @param {BriefingSubGroup} subgroup
 */
const addSubGroup = (groupKey, subgroup) => {
  groupKey = groupKey.toLowerCase();
  subgroup.key = subgroup.key.toLowerCase();
  subgroup.tables = subgroup.tables.map(t => ({...t, key: t.key.toLowerCase()}))
  const groups = getGroups();

  const groupIndex = groups.findIndex(g => g.key === groupKey);

  if(groupIndex === -1) {
    //Group doesn't exists
    //Create group with subgroup
    groups.push({
      key: groupKey,
      subgroups: [subgroup]
    });


  }else {
    //Group already exists
    const subGroupIndex = groups[groupIndex].subgroups.findIndex(sg => sg.key === subgroup.key);


    if(subGroupIndex === -1) {
      //Subgroup doesn't exists
      //Create subgroup
      groups[groupIndex].subgroups.push(subgroup)
    }else{
      //Subgroup already exists
      //Add table to subgroup

      //Filter already saved tables that are not in the given subgroup
      const filteredTables = groups[groupIndex].subgroups[subGroupIndex].tables.filter(t => !subgroup.tables.some(_t => _t.key === t.key))

      //Merge the tables
      groups[groupIndex].subgroups[subGroupIndex].tables = [
        ...filteredTables,
        ...subgroup.tables,
      ];

    }
  }




  window.consentedStorage.setItem("briefingGroups", JSON.stringify({
    groups: groups,
    updatedAt: new Date().getTime(),
  }))
}


/**
 * @param {string} groupKey
 */
const deleteGroup = (groupKey) => {
  groupKey = groupKey.toLowerCase();
  const groups = getGroups().filter(g => g.key !== groupKey);

  window.consentedStorage.setItem("briefingGroups", JSON.stringify({
    groups: groups,
    updatedAt: new Date().getTime(),
  }))
}

/**
 * @param {string} groupKey
 * @param {string} subgroupKey
 */
 const deleteSubgroup = (groupKey, subgroupKey) => {
  groupKey = groupKey.toLowerCase();
  const groups = getGroups().map(g => {
    g.subgroups = g.subgroups.filter(sg => sg.key !== subgroupKey);
    return g;
  }).filter(g => g.subgroups.length > 0);

  window.consentedStorage.setItem("briefingGroups", JSON.stringify({
    groups: groups,
    updatedAt: new Date().getTime(),
  }))
}

/**
 * @param {string} groupKey
 * @param {string} subgroupKey
 * @param {string} tableKey
 * @returns {boolean}
 */
const isTableExists = (groupKey, subgroupKey, tableKey) => {
  groupKey = groupKey.toLowerCase();
  subgroupKey = subgroupKey.toLowerCase();
  tableKey = tableKey.toLowerCase();

  const groups = getGroups();
  return groups.some(g => {
    if(g.key !== groupKey) return false;

    return g.subgroups.some(sg => {
      if(sg.key !== subgroupKey) return false;

      return sg.tables.some(t => t.key === tableKey);
    })
  })
}


export default {
  getGroups,
  addSubGroup,
  isTableExists,
  deleteGroup,
  deleteSubgroup
}
