

/**
 * Get the icon for the plane
 * @param {BeaconType} beaconType - The beacon type of the plane
 * @param {boolean} active - If the plane is active
 * @returns {NodeRequire} - The icon for the plane
 */
export function getPlaneIcon(beaconType, active = false) {
	const suffix = active ? '_blue.png' : '_black.png';
	if (beaconType === "UFO") return require(`@/assets/ufo${suffix}`);
	if (beaconType === "GLIDER") return require(`@/assets/glider${suffix}`);
	if (beaconType === "JET") return require(`@/assets/jet${suffix}`);

	if (beaconType === 'PARA_GLIDER') return require(`@/assets/paraglider${suffix}`);
	if (beaconType === 'HAND_GLIDER') return require(`@/assets/paraglider${suffix}`);
	if (beaconType === 'PARACHUTE') return require(`@/assets/paraglider${suffix}`);

	if (beaconType === 'HELICOPTER') return require(`@/assets/helicopter${suffix}`);
	if (beaconType === 'GYROCOPTER') return require(`@/assets/helicopter${suffix}`);

	if (beaconType === "CAR") return require(`@/assets/car${suffix}`);

	return require(`@/assets/airplane${suffix}`);
}

/**
 * Parse plane data that comes in a string format
 * @param {Plane} plane - The plane data
 * @returns {Plane} - The parsed plane data
 */
export function parsePlane(plane) {
	try{
		return {
			...plane,
			gravityPoints: JSON.parse(plane.gravityPoints || "[]"),
		}
	}catch (e) {
		console.error(e);
		return plane;
	}
}

