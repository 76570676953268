
import {store} from '../store/store'

(function() {
	let MergedList = {
		get: function(target, name) {
			if(name === Symbol.iterator) {
				return function*() {
					for(let l of target.lists) {
						for(let v of l) {
							yield v;
						}
					}
				}
			}

			if(name === Symbol.toStringTag) {
				return 'MergedList';
			}

			if(name === 'length') {
				let sum = 0;
				for(let l of target.lists) {
					sum += l.length;
				}
				return sum;
			}

			if(name === 'push') {
				return (val) => {
					target.lists.push(val);
				}
			}

			if(name === 'clear') {
				return () => {
					target.lists = [];
				}
			}

			let i = name;
			for(let l of target.lists) {
				if(i < l.length) {
					return l[i];
				}
				i -= l.length;
			}

			return undefined;
		}
	};

	window.L.Control.MultiPolyline = window.L.Class.extend({

		polylines: null,
		options: {
			noInternetColor: '#000000',
			noInternetOpacity: 0.5
		},
		map: null,
		bounds: null,
		allCoordinates: null,

		initialize: function(coordinates, options) {
			this.polylines = [];

			window.L.Util.setOptions(this, options);
			this.map = options.map;
			this._sortCoordinates(coordinates);

			let latLngs = [];
			let coords = [];
			let noInternet = false;
			let startTime = 0;
			let endTime = 0;

			if(coordinates.length > 0) {
				noInternet = coordinates[0].noInternet;
				startTime = coordinates[0].timeInstant;
			}

			coordinates.forEach((coordinate) => {
				endTime = coordinate.timeInstant;
				let latLng = [coordinate.latitude, coordinate.longitude];

				if(coordinate.noInternet != noInternet) {
					latLngs.push(latLng);
					coords.push(coordinate);
					this._addRoute(latLngs, coords, noInternet, startTime, endTime);
					latLngs = [];
					coords = [];
					noInternet = coordinate.noInternet;
					startTime = coordinate.timeInstant;
				}

				latLngs.push(latLng);
				coords.push(coordinate);
			});

			this._addRoute(latLngs, coords, noInternet, startTime, endTime);
		},

		addCoordinate(coordinate) {
			this._setCoordinateTime(coordinate);

			let polyLineToInsertPoint = null;
			let lastIndex = this.polylines.length - 1;
			let mode = 0;

			for(let p = 0; p < this.polylines.length; p++) {
				let polyline = this.polylines[p];

				if(p == 0 && coordinate.timeInstant <= polyline.startTime) {
					mode = 1; // A beszúrandó pont a legelső polyline darab előtt keletkezett.
					polyLineToInsertPoint = polyline;
					break;
				}
				if(p == lastIndex && coordinate.timeInstant >= polyline.endTime) {
					mode = 2; // A beszúrandó pont a legutolsó polyline után keletkezett.
					polyLineToInsertPoint = polyline;
					break;
				}
				if(polyline.startTime >= coordinate.timeInstant && polyline.endTime < coordinate.timeInstant) {
					mode = 3; // A beszúrandó pont az aktuálisan vizsgált polyline ideje alatt keletkezett.
					polyLineToInsertPoint = polyline;
					break;
				}
			}

			let latLng = [coordinate.latitude, coordinate.longitude];

			if(polyLineToInsertPoint == null) {
				this._addRoute([latLng], [coordinate], coordinate.noInternet, coordinate.timeInstant, coordinate.timeInstant);
			}
			else if(polyLineToInsertPoint.noInternet == coordinate.noInternet) {
				// Ha a pontot olyan polyline-ba szúrjuk be, amelyik noInternet flagja egyezik a pont noInternet flagjével, akkor csak ehhez hozzá kell adni.

				if(mode == 1) {
					// A beszúrandó pont a polyline előtt keletkezett, így beszúrjuk első pontnak.

					let latLngs = polyLineToInsertPoint.getLatLngs();
					latLngs.splice(0, 0, latLng);
					polyLineToInsertPoint.setLatLngs(latLngs);

					polyLineToInsertPoint.coordinates.splice(0, 0, coordinate);
					polyLineToInsertPoint.startTime = coordinate.timeInstant;
				}
				else if(mode == 3) {
					// A beszúrandó pont az polyline ideje alatt keletkezett.

					// Megkeressük, az első olyan pontot a polyline-ban, amelyik ideje már későbbi, mint a beszúrandó pont idejénél.
					let foundIndex = this._searchCoordinateIndex(polyLineToInsertPoint, coordinate);

					if(foundIndex == -1) {
						// Ha véletlen nem találnánk meg, akkor csak beszúrjuk a végére.
						mode = 2;
					}
					else {
						// Ha megtaláljuk, akkor beszúrjuk elé.
						let latLngs = polyLineToInsertPoint.getLatLngs();
						latLngs.splice(foundIndex, 0, latLng);
						polyLineToInsertPoint.setLatLngs(latLngs);

						polyLineToInsertPoint.coordinates.splice(foundIndex, 0, coordinate);
					}
				}
				if(mode == 2) {
					// A beszúrandó pont a polyline után keletkezett, így csak hozzáadjuk a végéhez.

					polyLineToInsertPoint.addLatLng(latLng);

					polyLineToInsertPoint.coordinates.push(coordinate);
					polyLineToInsertPoint.endTime = coordinate.timeInstant;
				}

			}
			else {
				// Ha a pontot olyan polyline-ba szúrjuk be, amelyik noInternet flagja NEM egyezik a pont noInternet flagjével, akkor jön a bonyolult rész.

				if(mode == 1) {
					// A beszúrandó pont a polyline előtt keletkezett, a pontot egy teljesen új útvonalként beszúrjuk az első polyline elé.

					let latLngs = [latLng, polyLineToInsertPoint.getLatLngs()[0]];
					let coordinates = [coordinate, polyLineToInsertPoint.coordinates[0]];

					this._addRoute(latLngs, coordinates, coordinate.noInternet, coordinate.timeInstant, coordinates[coordinates.length - 1].timeInstant);
				}
				else if(mode == 3) {
					// A beszúrandó pont az polyline ideje alatt keletkezett.

					// Megkeressük, az első olyan pontot a polyline-ban, amelyik ideje már későbbi, mint a beszúrandó pont idejénél.
					let foundIndex = this._searchCoordinateIndex(polyLineToInsertPoint, coordinate);

					if(foundIndex == -1) {
						// Ha véletlen nem találnánk meg, akkor csak beszúrjuk a végére új útvonalként.
						mode = 2;
					}
					else {
						// Ha megtaláljuk, akkor kettévágjuk az útvonalat, és a kettő közé beszúrjuk harmadiknak.
						let latLngs = polyLineToInsertPoint.getLatLngs();
						let latLngs1 = latLngs.slice(0, foundIndex);
						let latLngs2 = [latLng, latLngs1[latLngs1.length - 1]];
						let latLngs3 = latLngs.slice(foundIndex, latLngs.length);

						let coordinates = polyLineToInsertPoint.coordinates;
						let coordinates1 = coordinates.slice(0, foundIndex);
						let coordinates2 = [coordinate, coordinates1[coordinates1.length - 1]];
						let coordinates3 = coordinates.slice(foundIndex, latLngs.length);

						polyLineToInsertPoint.setLatLngs(latLngs1);
						polyLineToInsertPoint.coordinates = coordinates1;
						polyLineToInsertPoint.endTime = coordinates1[coordinates1.length - 1].timeInstant;

						this._addRoute(latLngs2, coordinates2, coordinate.noInternet, coordinate.timeInstant, coordinates2[coordinates2.length - 1].timeInstant);
						this._addRoute(latLngs3, coordinates3, coordinates3[0].noInternet, coordinates3[0].timeInstant, coordinates3[coordinates3.length - 1].timeInstant);
					}
				}
				if(mode == 2) {
					// A beszúrandó pont a polyline után keletkezett, így egy új útvonalként hozzáadjuk.

					polyLineToInsertPoint.addLatLng(latLng);
					this._addRoute([latLng], [coordinate], coordinate.noInternet, coordinate.timeInstant, coordinate.timeInstant);
				}
			}

			this.recalcAllCoordinates();
		},

		_searchCoordinateIndex(polyline, coordinate) {
			let l = 0;
			let r = polyline.coordinates.length - 1;
			let index = -1;

			while(l <= r) {
				let m = parseInt((l + r) / 2, 10);
				let c = polyline.coordinates[m];

				if(c.timeInstant < coordinate.timeInstant) {
					l = m + 1;
				}
				else {
					index = m;
					r = m - 1;
				}
			}

			return index;
		},

		_searchPolyline(startTime) {
			let l = 0;
			let r = this.polylines.length - 1;
			let index = -1;

			while(l <= r) {
				let m = parseInt((l + r) / 2, 10);
				let c = this.polylines[m];

				if(c.startTime < startTime) {
					l = m + 1;
				}
				else {
					index = m;
					r = m - 1;
				}
			}

			return index;
		},

		_setCoordinateTime(coordinate) {
			coordinate.timeInstant = coordinate.time ? Date.parse(coordinate.time) : 0;
		},

		_sortCoordinates(coordinates) {
			coordinates.forEach((coordinate) => this._setCoordinateTime(coordinate));
			coordinates.sort((a, b) => a.timeInstant - b.timeInstant);
		},

		_addRoute(latLngs, coordinates, noInternet, startTime, endTime) {
			if(latLngs.length > 0) {
				let pl = window.L.polyline(latLngs, this.options);
				pl.noInternet = noInternet;
				pl.startTime = startTime;
				pl.endTime = endTime;
				pl.coordinates = coordinates;
				if(pl.noInternet) {
					pl.setStyle({ opacity: this.options.noInternetOpacity, color: this.options.noInternetColor });
				}

				pl.on("click", function(e) {
					store.dispatch('storePolyLineClickEvent', e.latlng)
				});

				let foundIndex = this._searchPolyline(pl.startTime);

				if(foundIndex == -1) {
					this.polylines.push(pl);
				}
				else {
					this.polylines.splice(foundIndex, 0, pl);
				}

				pl.addTo(this.map);

				if(this.bounds == null) {
					this.bounds = window.L.latLngBounds(pl.getBounds());
				}
				else {
					this.bounds.extend(pl.getBounds());
				}

				return pl;
			}
			return null;
		},

		addTo(map) {
			this.polylines.forEach((pl) => pl.addTo(map));
		},

		removeFrom(map) {
			this.polylines.forEach((pl) => pl.removeFrom(map));
		},

		remove() {
			this.polylines.forEach((pl) => pl.remove());
		},

		getBounds() {
			return this.bounds;
		},

		getCoordinates() {
			if(this.allCoordinates === null) {
				this.recalcAllCoordinates();
			}
			return this.allCoordinates;
		},

		recalcAllCoordinates() {
			this.allCoordinates = new Proxy({lists: []}, MergedList);
			for(let pl of this.polylines) {
				this.allCoordinates.push(pl.coordinates);
			}
		}

	});

	window.L.multiPolyline = function(arr, opts) {
		return new window.L.Control.MultiPolyline(arr, opts);
	}

})();
