"use strict";

import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import base64 from "base-64";

function b64enc(value) {
	if(typeof value === 'object') {
		value = JSON.stringify(value);
	}
	return base64.encode(value);
}

function b64dec(value) {
	return base64.decode(value);
}

const _mikrotik = {
	install() {
		Object.defineProperty(Vue.prototype, '$mikrotik', {
			get() {
				return _mikrotik;
			}
		});
	}
};

_mikrotik.callbackMap = {};
_mikrotik.timeoutMap = {};

_mikrotik.onRequestStart = function(uuid) {
	document.getElementById('app').__vue__.showMikrotikOverlay = true;

	return new Promise((resolve, reject) => {
		_mikrotik.callbackMap[uuid] = resolve;
		_mikrotik.timeoutMap[uuid] = setTimeout(() => _mikrotik.onTimeout(uuid, reject), 5000);
	});
}

_mikrotik.onRequestEnd = function(uuid, response) {
	let timer = _mikrotik.timeoutMap[uuid];
	if(timer) {
		clearTimeout(timer);
		delete _mikrotik.timeoutMap[uuid];
	}

	let promiseResolve = _mikrotik.callbackMap[uuid];
	if(promiseResolve) {
		promiseResolve(b64dec(response));

		document.getElementById('app').__vue__.showMikrotikOverlay = false;

		delete _mikrotik.callbackMap[uuid];
	}
}

_mikrotik.onTimeout = function(uuid, reject) {
	if(!_mikrotik.timeoutMap[uuid]) {
		return;
	}

	reject('Mikrotik timeout');

	document.getElementById('app').__vue__.showMikrotikOverlay = false;

	delete _mikrotik.callbackMap[uuid];
	delete _mikrotik.timeoutMap[uuid];
}

_mikrotik.isOnMikrotikNetwork = async function() {
	try {
		let _response = await _mikrotik.httpGet('/');
		return _response && _response.toLowerCase().indexOf('mikrotik') >= 0;
	}
	catch(_err) {
		//console.error(_err);
		return false;
	}
}

_mikrotik.getIdentity = async function() {
	try {
		let _response = await _mikrotik.httpGet('/rest/system/identity');
		let resp = JSON.parse(_response);
		return resp.name;
	}
	catch(_err) {
		//console.error(_err);
		return null;
	}
}

_mikrotik.setIdentity = async function(identity) {
	try {
		await _mikrotik.httpPost('/rest/system/identity/set', { name: identity });
	}
	catch(_err) {
		//console.error(_err);
	}
	return identity;
}

_mikrotik.getEnvironmentVariables = async function() {
	try {
		let _response = await _mikrotik.httpGet('/rest/system/script/environment');
		
		let resp0 = JSON.parse(_response);
		let resp = {};
		for(let r of resp0) {
			if(r.name && r.value && r.value.length < 256) {
				resp[r.name] = r.value;
			}
		}
		return resp;
	}
	catch(_err) {
		//console.error(_err);
		return {};
	}
}

_mikrotik.getEnvironmentVariable = async function(variable) {
	try {
		let _response = await _mikrotik.httpGet('/rest/system/script/environment/' + variable);
		console.log("_response", _response);
		let resp = JSON.parse(_response);
		return resp.value;
	}
	catch(_err) {
		//console.error(_err);
		return null;
	}
}

_mikrotik.setEnvironmentVariable = async function(variable, value, callback) {
	try {
		return await _mikrotik.httpPatch('/rest/system/script/environment/' + variable, { value: value });
	}
	catch(_err) {
		//console.error(_err);
		return null;
	}
}

_mikrotik.disableScheduler = async function(schedulerName) {
	try {
		return await _mikrotik.httpPost('/rest/system/scheduler/set', { '.id': schedulerName, disabled: 'yes' });
	}
	catch(_err) {
		//console.error(_err);
		return null;
	}
}

_mikrotik.enableScheduler = async function(schedulerName) {
	try {
		return await _mikrotik.httpPost('/rest/system/scheduler/set', { '.id': schedulerName, disabled: 'no' });
	}
	catch(_err) {
		//console.error(_err);
		return null;
	}
}

_mikrotik.httpGet = async function(url) {
	if('ios' === window.platform || 'android' === window.platform) {		
		let uuid = uuidv4();
		let promise = _mikrotik.onRequestStart(uuid);

		if(url === '/') {
			window.loadAppLink('r2gflightserver://mikrotik-get?uuid=' + uuid + '&url=' + url + '&noauth=1');
		}
		else {
			window.loadAppLink('r2gflightserver://mikrotik-get?uuid=' + uuid + '&url=' + url);
		}

		return await promise;
	}
	throw Error('Not called from native app.');
}

_mikrotik.httpPost = async function(url, body) {
	if('ios' === window.platform || 'android' === window.platform) {
		let uuid = uuidv4();
		let promise = _mikrotik.onRequestStart(uuid);

		window.loadAppLink('r2gflightserver://mikrotik-post?uuid=' + uuid + '&url=' + url + '&body=' + b64enc(body));

		return await promise;
	}
	throw Error('Not called from native app.');
}

_mikrotik.httpPatch = async function(url, body) {
	if('ios' === window.platform || 'android' === window.platform) {
		let uuid = uuidv4();
		let promise = _mikrotik.onRequestStart(uuid);

		window.loadAppLink('r2gflightserver://mikrotik-patch?uuid=' + uuid + '&url=' + url + '&body=' + b64enc(body));

		return await promise;
	}
	throw Error('Not called from native app.');
}

_mikrotik.feedGet = function(uuid, response) {
	_mikrotik.onRequestEnd(uuid, response);
}

_mikrotik.feedPost = function(uuid, response) {
	_mikrotik.onRequestEnd(uuid, response);
}

_mikrotik.feedPatch = function(uuid, response) {
	_mikrotik.onRequestEnd(uuid, response);
}

Vue.use(_mikrotik);