import queueThat from "queue-that";
import { rest } from "./axios";

export default queueThat({

	/**
	 * This label is used to store the queue in localStorage.
	 * If there are more than one queues, unique labels are required
	 * to have them run without interference.
	 *
	 * @optional
	 * @type {String}
	 * @default 'Queue That'
	 */
	label: 'EventQueue',

	/**
	 * Asynchronous function to process batches of tasks.
	 * The queue will not process any more tasks until done is called
	 * or the event times out.
	 *
	 * @required
	 * @type {Function}
	 */
	process: function (batch, done) {
		// if(!window.checkedIn) {
		// 	console.log("Not checked in yet, skipping events");
		// 	return
		// }
		this.processAsync(batch)
			.then(() => done())
			.catch(() => null);
	},

	/**
	 * Truly Asynchronous function to process batches of tasks.
	 * @param {{
	 *   type: UserEventType,
	 *   data: any,
	 *   time: string
	 * }[]} batch
	 * @returns {Promise<void>}
	 */
	processAsync: async function (batch) {
		batch = batch
			.sort((a, b) => a.time - b.time)
			.sort((a, b) => a.type === "CHECK_IN" ? -1 : 1);

		for (let event of batch) {
			const r = await rest.event(event.type, event.data, event.time);
			if(!r){
				throw new Error("Failed to process event");
			}
		}
	},

	/**
	 * Every time the queue is set, this setter will be called.
	 * Good for reducing the queue size if it gets too long.
	 *
	 * @optional
	 * @type {Function}
	 */
	trim: function (batch) {
		return batch;
	},

	/**
	 * The time used to group tasks.
	 *
	 * @optional
	 * @type {Number}
	 */
	queueGroupTime: 100,

	/**
	 * The queue will wait this time when there is an
	 * error before calling `options.process` again.
	 * The time doubles on every error and resets on a success.
	 *
	 *
	 * @optional
	 * @type {Number}
	 * @default 1000
	 */
	backoffTime: 1000,

	/**
	 * The queue will assume an error and backoff if a
	 * task takes longer than this to process.
	 *
	 * @optional
	 * @type {Number}
	 * @default 2000
	 */
	processTimeout: 2000,

	/**
	 * Another queue may become active if a queue hasn't
	 * flagged itself as active for longer than this time.
	 * This time must be longer than processTimeout.
	 *
	 * @optional
	 * @type {Number}
	 * @default 2500
	 */
	activeQueueTimeout: 2500
})

