<template>
	<input
			:id="id"
			type="tel"
			:name="$attrs.name"
			:value="phone"
			@change="change"
	/>
</template>

<script>
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export default {
	name: 'PhoneInput',
	data() {
		return {
			phone: ''
		};
	},
	props: {
		id: String,
		value: String,
		msgs: Object,
		required: Boolean
	},
	mounted() {
		this.phone = this.value;
	},
	watch: {
		value(v) {
			this.phone = v;
		}
	},
	methods: {
		async change(event) {

			let val = event.target.value.trim();

			if(!val && !this.required) {
				event.target.value = val;
				this.phone = val;
				this.$emit('input', val);
				this.$set(this.msgs, this.id, []);
				return;
			}

			if(!val && this.required) {
				event.target.value = val;
				this.text = val;
				this.$emit('input', val);
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}]);
				return;
			}

			try {
				const phoneUtil = PhoneNumberUtil.getInstance();
				let number = phoneUtil.parseAndKeepRawInput(val, 'HU');
				if(phoneUtil.isValidNumber(number)) {
					val = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
					event.target.value = val;
					this.phone = val;
					this.$emit('input', val);
					this.$set(this.msgs, this.id, []);
					return;
				}
			}
			catch(err) {
				console.log(err);
			}

			this.$emit('change', '');
			this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.phone'), severity: 'error'}]);
		}
	},
	emits: ['input']
}
</script>
