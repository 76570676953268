import Vue from 'vue'
import Router from 'vue-router'
import OfflineLayersView from "@/views/OfflineLayersView.vue";
import MyFilesVIew from "@/views/MyFilesView.vue";


// Licence Views
const FontAwesomeLicence = () => import(`./views/licences/FontAwesomeLicenseView`);
const ApacheLicenseView = () => import(`./views/licences/ApacheLicenseView`);
const VueLicenseView = () => import(`./views/licences/VueLicenseView`);
const PopperLicenseView = () => import(`./views/licences/PopperLicenseView`);
const LoadashLicenseView = () => import(`./views/licences/LoadashLicenseView`);
const UrlSearchParamsPolyfillView = () => import(`./views/licences/UrlSearchParamsPolyfillView`);
const AnimateCssView = () => import(`./views/licences/AnimatCssView`);
const AxiosLicenseView = () => import(`./views/licences/AxiosLicenseView`);
const FlickingPluginView = () => import(`./views/licences/FlickingPluginView`);
const JqueryLicenseView = () => import(`./views/licences/JqueryLicenseView`);
const VuexLicenseView = () => import(`./views/licences/VuexLicenseView`);
const OpenSourceSoftwareView = () => import(`./views/licences/OpenSourceSoftwareView`);

// Static Views
const IndexView = () => import(`./views/static/IndexView`);
const PrivacyStatementView = () => import(`./views/static/PrivacyStatementView`);
const PrivacyStatementViewHU = () => import(`./views/static/PrivacyStatementViewHU`);
const GtcView = () => import(`./views/static/GtcView`);
const GtcViewHU = () => import(`./views/static/GtcViewHU`);
const ContactView = () => import(`./views/static/ContactView`);
const HelpView = () => import(`./views/static/HelpView`);


// Profile Views
const LoginView = () => import(`./views/profile/LoginView`);
const RegisterView = () => import(`./views/profile/RegisterView`);
const ForgottenPasswordView = () => import(`./views/profile/ForgottenPasswordView`);
const ProfileView = () => import(`./views/profile/ProfileView`);
const RegistrationResultView = () => import(`./views/profile/RegistrationResultView`);
const SetPasswordView = () => import(`./views/profile/SetPasswordView`);

// Plane Views
const PlaneView = () => import(`./views/plane/PlaneView`);
const PlanesView = () => import(`./views/plane/PlanesView`);
const PlaneChangesView = () => import(`./views/plane/PlaneChangesView`);
const VerifyPlaneView = () => import(`./views/plane/VerifyPlaneView`);

// Route Plan Views
const RoutePlanningView = () => import(`./views/routePlan/RoutePlanningView`);
const RoutePlanElementEditView = () => import(`./views/routePlan/RoutePlanElementEditView`);
const RoutePlanElementShowView = () => import(`./views/routePlan/RoutePlanElementShowView`);

const MapView = () => import(`./views/MapView`);
const StartFlightView = () => import(`./views/StartFlightView`);
const FlyingView = () => import(`./views/FlyingView`);
const NotamObserverView = () => import(`./views/NotamObserverView`);
const NotamListView = () => import(`./views/NotamListView`);
const FlightObserverView = () => import(`./views/FlightObserverView`);
const FlightListView = () => import(`./views/FlightListView`);
const CogView = () => import(`./views/CogView`);
const WeatherView = () => import(`./views/WeatherView`);
const FlightPlanView = () => import(`./views/FlightPlanView`);
const NoteListView = () => import(`./views/NoteListView`);
const ConverterView = () => import(`./views/ConverterView`);
const BriefingView = () => import(`./views/BriefingView`);
const QuestionnaireView = () => import(`./views/questionnaire/QuestionnaireView`);
const UnsubscribeView = () => import(`./views/UnsubscribeView`);





Vue.use(Router)

export default new Router({
	routes: [
		{
			name: 'mapOld',
			path: '/map',
			component: MapView,
			meta: {
				title: 'pageTitle.map',
				loginRequired: false,
				fullscreen: true,
				redirectTo: '/'
			}
		},
		{
			name: 'login',
			path: '/login',
			component: LoginView,
			meta: {
				onlyGuest: true,
				title: 'pageTitle.login'
			}
		},
		{
			name: 'register',
			path: '/register',
			component: RegisterView,
			meta: {
				onlyGuest: true,
				title: 'pageTitle.register'
			}
		},
		{
			path: '/forgotten-password',
			name: 'forgotten-password',
			component: ForgottenPasswordView,
			meta: {
				title: 'pageTitle.forgottenPassword'
			}
		},
		{
			name: 'registration-result',
			path: '/registration-result',
			component: RegistrationResultView,
			meta: {
				title: 'pageTitle.registrationresult',
			}
		},
		{
			name: 'set-password',
			path: '/set-password/:token',
			component: SetPasswordView,
			meta: {
				title: 'pageTitle.setPassword',
			}
		},
		{
			name: 'contact',
			path: '/contact',
			component: ContactView,
			meta: {
				title: 'pageTitle.contact',
				fullscreen: false
			}
		},
		{
			name: 'help',
			path: '/help',
			component: HelpView,
			meta: {
				title: 'pageTitle.help',
				fullscreen: false
			}
		},
		{
			name: 'openSource',
			path: '/open-source',
			component: OpenSourceSoftwareView,
			meta: {
				title: 'pageTitle.aboutus',
				fullscreen: false
			}
		},
		{
			name: 'start-flight',
			path: '/start-flight',
			component: StartFlightView,
			meta: {
				title: 'pageTitle.start-flight',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'flying-map',
			path: '/flying-map/:registrationNumber/:routeIdUUID?',
			component: FlyingView,
			meta: {
				title: 'pageTitle.map',
				loginRequired: false,
				fullscreen: true
			}
		},
		{
			name: 'map',
			path: '/',
			component: MapView,
			meta: {
				title: 'pageTitle.map',
				loginRequired: false,
				fullscreen: true
			}
		},

		{
			name: 'route-planning',
			path: '/route-planning',
			component: RoutePlanningView,
			meta: {
				title: 'menu.route-planning',
				loginRequired: true,
				fullscreen: true
			}
		},
		{
			name: 'route-planning-element',
			path: '/route-planning-element/:id',
			component: RoutePlanElementEditView,
			meta: {
				title: 'menu.route-planning',
				loginRequired: true,
				fullscreen: true
			}
		},
		{
			name: 'show-route-planning-element',
			path: '/show-route-planning-element/:id',
			component: RoutePlanElementShowView,
			meta: {
				title: 'menu.route-planning',
				loginRequired: true,
				fullscreen: true
			}
		},
		{
			name: 'flights',
			path: '/flights',
			component: FlightListView,
			meta: {
				title: 'pageTitle.flightList',
				loginRequired: true,
				fullscreen: true
			}
		},
		{
			name: 'flight-observer',
			path: '/flight-observer/:id',
			component: FlightObserverView,
			meta: {
				title: 'pageTitle.flightObserver',
				loginRequired: true,
				fullscreen: true
			}
		},
    {
			name: 'notam-observer',
			path: '/notam-observer/:id',
			component: NotamObserverView,
			meta: {
				title: 'pageTitle.notamObserver',
				loginRequired: true,
				fullscreen: true
			}
		},
		{
			name: 'me',
			path: '/me',
			component: ProfileView,
			meta: {
				title: 'pageTitle.me',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'weather',
			path: '/weather',
			component: WeatherView,
			meta: {
				title: 'pageTitle.weather',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'briefing-report',
			path: '/briefing-report',
			component: BriefingView,
			meta: {
				title: 'pageTitle.briefingReport',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'notam',
			path: '/notam',
			component: NotamListView,
			meta: {
				title: 'pageTitle.notam',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'cog',
			path: '/cog',
			component: CogView,
			meta: {
				title: 'pageTitle.cog',
				loginRequired: true,
				fullscreen: false
			}
		},
    {
			name: 'converter',
			path: '/converter',
			component: ConverterView,
			meta: {
				title: 'pageTitle.converter',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'unsubscribe',
			path: '/unsubscribe',
			component: UnsubscribeView,
			meta: {
				title: 'pageTitle.unsubscribe',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'flight-plan',
			path: '/flight-plan',
			component: FlightPlanView,
			meta: {
				title: 'pageTitle.flightPlan',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'notes',
			path: '/notes',
			component: NoteListView,
			meta: {
				title: 'pageTitle.notes',
				loginRequired: true,
				fullscreen: false
			}
		},
		{
			name: 'questionnaire_old',
			path: '/questionnaire/:token',
			component: QuestionnaireView,
			meta: {
				title: 'pageTitle.questionnaire',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'questionnaire',
			path: '/questionnaire/:campaign/:token',
			component: QuestionnaireView,
			meta: {
				title: 'pageTitle.questionnaire',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'gtc',
			path: '/gtc',
			component: GtcView,
			meta: {
				title: 'pageTitle.gtc',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'gtc-hu',
			path: '/gtc-hu',
			component: GtcViewHU,
			meta: {
				title: 'pageTitle.gtc',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'privacy-statement',
			path: '/privacy-statement',
			component: PrivacyStatementView,
			meta: {
				title: 'pageTitle.privacyStatement',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			name: 'privacy-statement-hu',
			path: '/privacy-statement-hu',
			component: PrivacyStatementViewHU,
			meta: {
				title: 'pageTitle.privacyStatement',
				loginRequired: false,
				fullscreen: false
			}
		},
		{
			path: '/planes',
			name: 'planes',
			component: PlanesView,
			meta: {
				title: 'pageTitle.planes',
				loginRequired: true,
			}
		},
		{
			path: '/plane-changes',
			name: 'planeChanges',
			component: PlaneChangesView,
			meta: {
				title: 'pageTitle.plane-changes',
				loginRequired: true,
			}
		},
		{
			path: '/plane',
			name: 'planeCreate',
			component: PlaneView,
			meta: {
				title: 'pageTitle.plane',
				loginRequired: true,
			}
		},
		{
			path: '/plane/:id',
			name: 'plane',
			component: PlaneView,
			meta: {
				title: 'pageTitle.plane',
				loginRequired: true,
			}
		},
		{
			path: '/plane/:id/verify',
			name: 'verifyPlane',
			component: VerifyPlaneView,
			meta: {
				title: 'pageTitle.verifyPlane',
			}
		},
		{
			name: 'offline-layers',
			path: '/offline-layers',
			component: OfflineLayersView,
			meta: {
				title: 'pageTitle.offline-layers',
			}
		},
		{
			name: 'myfiles',
			path: '/myfiles',
			component: MyFilesVIew,
			meta: {
				title: 'pageTitle.myfiles',
			}
		},
		{
			name: 'font-licence',
			path: '/font-license',
			component: FontAwesomeLicence,
			meta: {
				title: 'pageTitle.font-licence',
			}
		},
		{
			name: 'apache-licence',
			path: '/apache-license',
			component: ApacheLicenseView,
			meta: {
				title: 'pageTitle.apache-licence',
			}
		},
		{
			name: 'loadash-licence',
			path: '/loadash-license',
			component: LoadashLicenseView,
			meta: {
				title: 'pageTitle.loadash-licence',
			}
		},
		{
			name: 'vue-licence',
			path: '/vue-license',
			component: VueLicenseView,
			meta: {
				title: 'pageTitle.vue-licence',
			}
		},
		{
			name: 'popper-licence',
			path: '/popper-license',
			component: PopperLicenseView,
			meta: {
				title: 'pageTitle.popper-licence',
			}
		},
		{
			name: 'url-search-params-polyfill-licence',
			path: '/url-search-params-polyfill-license',
			component: UrlSearchParamsPolyfillView,
			meta: {
				title: 'pageTitle.url-search-params-polyfill-licence',
			}
		},
		{
			name: 'animateCss-licence',
			path: '/animateCss-license',
			component: AnimateCssView,
			meta: {
				title: 'animateCss-licence',
			}
		},
		{
			name: 'axios-licence',
			path: '/axios-license',
			component: AxiosLicenseView,
			meta: {
				title: 'axios-licence',
			}
		},
		{
			name: 'flicking-plugins-licence',
			path: '/flicking-plugins-license',
			component: FlickingPluginView,
			meta: {
				title: 'flicking-plugins-licence',
			}
		},
		{
			name: 'jquery-licence',
			path: '/jquery-license',
			component: JqueryLicenseView,
			meta: {
				title: 'jquery-licence',
			}
		},
		{
			name: 'vuex-licence',
			path: '/vuex-license',
			component: VuexLicenseView,
			meta: {
				title: 'vuex-licence',
			}
		}
	]
})
