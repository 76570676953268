<template>
	<div class="question" v-if="!qa.question.disabled">
		<h5>
			{{ qa.question.name }}
			<span class="text-error font-weight-bold" v-if="qa.question.required">*</span>
		</h5>
		<p class="text-muted">
			{{ qa.question.description }}
		</p>

		<div v-if="qa.question.type === 'TEXT'">
			<div v-if="qa.question.multiple">
				<InputTextArea
					class="q-input full"
					:id="qa.question.id"
					v-model="qa.answer"
					:required="qa.question.required"
					:placeholder="qa.question.placeholder"
					:readonly="readonly"
					@change="saveAnswer"
				/>
			</div>
			<div v-else>
				<InputText
					class="q-input full"
					:id="qa.question.id"
					v-model="qa.answer"
					:required="qa.question.required"
					:placeholder="qa.question.placeholder"
					:readonly="readonly"
					@change="saveAnswer"
				/>
			</div>
		</div>


		<div v-if="qa.question.type === 'NUMBER'">
			<InputNumber
				class="q-input full"
				:id="qa.question.id"
				v-model="numberAnswer"
				:required="qa.question.required"
				:placeholder="qa.question.placeholder"
				:readonly="readonly"
				:min="qa.question.minNumber"
				:max="qa.question.maxNumber"
			/>
		</div>

		<div v-if="qa.question.type === 'RATING'">
			<Rating
				class="q-input full"
				:id="qa.question.id"
				v-model="qa.numberAnswer"
				:required="qa.question.required"
				:placeholder="qa.question.placeholder"
				:cancel="false"
				:readonly="readonly"
				@change="saveAnswer"
			/>
		</div>

		<div v-if="qa.question.type === 'LIST'">
			<div v-if="qa.question.multiple">
				<div class="mt-1 d-flex align-items-center" v-for="option in qa.question.options" v-bind:key="option.id">
					<Checkbox
						v-model="qa.answers"
						:inputId="option.id"
						:name="option.name"
						:value="option.id"
						@change="saveAnswer"
					/>
					<label class="ml-2 m-0" :for="option.id + 'listmulti'">{{ option.name }}</label>
				</div>
			</div>
			<div v-else-if="qa.question.allVisible">
				<div class="mt-1 d-flex align-items-center" v-for="option in qa.question.options" v-bind:key="option.id">
					<RadioButton
						v-model="qa.answer"
						:inputId="option.id"
						:name="option.id"
						:value="option.id"
						@change="saveAnswer"
					/>
					<label class="ml-2 m-0" :for="option.id + 'list'">{{ option.name }}</label>
				</div>
			</div>
			<div v-else>
				<select class=" form-control w-100"
								style="min-width: 5rem;"
								v-model="qa.answer"
								:id="qa.question.id"
								@change="saveAnswer"
				>
					<option disabled >{{ $t('questionnaire.choose') }}</option>
					<option v-for="option in qa.question.options" v-bind:key="option.id" :value="option.id">
						{{ option.name }}
					</option>
				</select>
			</div>
		</div>

		<div v-if="qa.question.type === 'DATE'">
			<Calendar
				class="q-input"
				:id="qa.question.id"
				v-model="qa.dateAnswer"
				:required="qa.question.required"
				:placeholder="qa.question.placeholder"
				:min-date="qa.question.minDate ? new Date(qa.question.minDate) : null"
				:max-date="qa.question.maxDate ? new Date(qa.question.maxDate) : null"
				:readonly="readonly"
				:showIcon="true"
				@blur="saveAnswer"
			/>
		</div>

		<div v-if="qa.question.type === 'BOOLEAN'">
			<InputSwitch
				class="q-input"
				:id="qa.question.id"
				v-model="boolAnswer"
				:required="qa.question.required"
				:placeholder="qa.question.placeholder"
				:readonly="readonly"
				:showIcon="true"
			/>
		</div>

		<div v-if="qa.question.type === 'UPLOAD'">
			<FileUpload
				:name="`file`"
				:url="uploadUrl"
				:multiple="qa.question.multiple"
				:accept="uploadAccept"
				@upload="onUpload($event)"
				:maxFileSize="qa.question.maxFileSize*1000"
				:chooseLabel="$t('questionnaire.upload_choose')"
				:uploadLabel="$t('questionnaire.upload_upload')"
				:cancelLabel="$t('questionnaire.upload_cancel')"
			>
				<template #empty>
					<p>{{ $t('questionnaire.empty_upload') }}</p>
				</template>
			</FileUpload>
			<div class="uploaded-files">
				<div class="uploaded-file" v-for="file in files" :key="file">
					<div class="file-preview">
						<font-awesome-icon icon="file" class="m-0" />
					</div>
					<a :href="baseUrl + '/questionnaire/' + questionnaireAnswer.token + '/' + qa.question.id + '/' + file">
						{{ file }}
					</a>
					<div class="file-actions">
						<button class="btn btn-danger btn-sm" @click="deleteFile(file)">
							<font-awesome-icon icon="trash" class="m-0" />
						</button>
					</div>
				</div>
			</div>
		</div>


		<ComponentMessage :msgs="msgs" :forComponent="field" />

	</div>
</template>

<script>
import Messages from "@/components/Messages.vue";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Calendar from "primevue/calendar/Calendar";
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import ComponentMessage from "@/components/ComponentMessage.vue";
export default {
	name: 'Question',
	components: { ComponentMessage, Checkbox, RadioButton, Calendar, InputSwitch, FileUpload },
	props: {
		readonly: {
			type: Boolean,
			default: false,
		},
		msgs: {
			type: Object,
			required: true,
		},
		questionnaireAnswer: {
			type: Object,
			required: true,
		},
		questionAnswer: {
			type: Object,
			required: true,
		},
	},
	computed: {
		baseUrl() {
			return window._rest.baseUrl;
		},
		files() {
			if(this.qa.question.type !== "UPLOAD") return [];
			if(!this.qa.answer) return [];
			return this.qa.answer.split(";");
		},
		field() {
			return `${this.qa.question.id}`;
		},
		uploadUrl(){
			return window._rest.baseUrl + "/questionnaire/" + this.questionnaireAnswer.token + "/" + this.qa.question.id + "/upload";
		},
		uploadAccept() {
			if(this.qa.question.allowedExtensions.length === 0) return "*";
			return this.qa.question.allowedExtensions.map(ext => "." + ext).join(",");
		},
	},
	data() {
		return {
			/** @type {QuestionAnswer} */
			qa: this.questionAnswer,
			timeout: null,
			boolAnswer: this.questionAnswer.answer === "1",
			numberAnswer: this.questionAnswer.numberAnswer,
		}
	},

	methods: {
		async deleteFile(file) {
			const newQa = await this.$rest.deleteQuestionAnswerFile(this.questionnaireAnswer.token, this.qa.question.id, file);
			if(!newQa) return;
			this.qa = {
				...newQa,
			}
		},
		async onUpload() {
			const newQa = await this.$rest.getQuestionAnswer(this.questionnaireAnswer.token, this.qa.question.id);
			if(!newQa) return;
			this.qa = {
				...newQa,
			}
		},
		saveAnswer(){
			if(this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => {
				try {
					const newQa = await this.$rest.saveQuestionAnswer(this.questionnaireAnswer.token, this.qa.question.id, this.qa);
					if(newQa) {
						this.qa = {
							...newQa,
							...this.qa,
						};
					}
				} catch (e) {
					console.error(e);
				}
			}, 500);
		},
		update() {
			this.qa = {
				...this.questionAnswer,
				...this.qa,
			}
		},
	},

	watch: {
		numberAnswer: function() {
			this.qa.numberAnswer = this.numberAnswer;
			this.saveAnswer();
		},
		boolAnswer: function() {
			this.qa.answer = this.boolAnswer ? "1" : "0";
			this.saveAnswer();
		},
		questionAnswer: function() {
			this.update()
		},
		questionnaireAnswer: function() {
			this.update()
		},
		question: function() {
			this.update()
		},
	},
}
</script>

<style scoped>

	.uploaded-files {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		width: 100%;
		margin-top: 1rem;
	}

	.uploaded-file {
		display: flex;
		align-items: center;
		gap: 1rem;
		background: whitesmoke;
		padding: 1rem;
		border-radius: 5px;
		border: 1px solid lightgrey;
	}

	.uploaded-file .file-preview {
		width: 3rem;
		height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: lightgrey;
		border-radius: 5px;
	}

	.uploaded-file .file-actions {
		margin-left: auto;
	}

	.uploaded-file a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}

	.question {
		margin-top: 2rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid lightgrey;
	}

	.question h5 {
		margin-bottom: 5px;
	}

	.q-input {
		margin-bottom: 5px;
	}

	.q-input.full {
		width: 100%;
	}

	.text-error {
		color: red;
	}
</style>
