// The reason for the redundant key values is to make it easier to list all units in a group without having to loop through the object keys.

import Vue from "vue";
import {t} from "@/i18n";
import _ from "lodash";

const _allUnits  = {
		// Pressure
		mb: {
			key: "mb",
			suffix: "mb",
			name: "unit.milibar",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		bar: {
			key: "bar",
			suffix: "bar",
			name: "unit.bar",
			trueValue: (v) => v * 1000,
			fromTrueValue: (t) => t / 1000
		},
		hPa: {
			key: "hPa",
			suffix: "hPa",
			name: "unit.hectopascal",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		Pa: {
			key: "Pa",
			suffix: "Pa",
			name: "unit.pascal",
			trueValue: (v) => v / 100,
			fromTrueValue: (t) => t * 100
		},
		inHg: {
			key: "inHg",
			suffix: "inHg",
			name: "unit.inHg",
			trueValue: (v) => v * 33.864,
			fromTrueValue: (t) => t / 33.864
		},
		mmHg: {
			key: "mmHg",
			suffix: "mmHg",
			name: "unit.mmHg",
			trueValue: (v) => v * 1.333,
			fromTrueValue: (t) => t / 1.333
		},

		// Distance
		m: {
			key: "m",
			suffix: "m",
			name: "unit.meter",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		cm: {
			key: "cm",
			suffix: "cm",
			name: "unit.cm",
			trueValue: (v) => v / 100,
			fromTrueValue: (t) => t * 100
		},
		in: {
			key: "in",
			suffix: "inch",
			name: "unit.inch",
			trueValue: (v) => v / 39.37,
			fromTrueValue: (t) => t * 39.37
		},
		ft: {
			key: "ft",
			suffix: "ft",
			name: "unit.feet",
			trueValue: (v) => v / 3.281,
			fromTrueValue: (t) => t * 3.281
		},
		yd: {
			key: "yd",
			suffix: "yards",
			name: "unit.yard",
			trueValue: (v) => v / 1.094,
			fromTrueValue: (t) => t * 1.094
		},
		mi: {
			key: "mi",
			suffix: "mi",
			name: "unit.mile",
			trueValue: (v) => v * 1609.344,
			fromTrueValue: (t) => t / 1609.344
		},
		nmi: {
			key: "nmi",
			suffix: "nmi",
			name: "unit.seamile",
			trueValue: (v) => v * 1852,
			fromTrueValue: (t) => t / 1852
		},
		km: {
			key: "km",
			suffix: "km",
			name: "unit.km",
			trueValue: (v) => v * 1000,
			fromTrueValue: (t) => t / 1000
		},
		fl: {
			key: "fl",
			suffix: "fl",
			name: "unit.fl",
			trueValue: (v) => v * 30.48,
			fromTrueValue: (t) => t / 30.48
		},

		// Mass
		g: {
			key: "g",
			suffix: "g",
			name: "unit.g",
			trueValue: (v) => v / 1000,
			fromTrueValue: (t) => t * 1000
		},
		kg: {
			key: "kg",
			suffix: "kg",
			name: "unit.kg",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		t: {
			key: "t",
			suffix: "t",
			name: "unit.t",
			trueValue: (v) => v * 1000,
			fromTrueValue: (t) => t / 1000
		},
		lbs: {
			key: "lbs",
			suffix: "lbs",
			name: "unit.lbs",
			trueValue: (v) => v / 2.205,
			fromTrueValue: (t) => t * 2.205
		},

		// Volume
		l: {
			key: "l",
			suffix: "l",
			name: "unit.liter",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		gal: {
			key: "gal",
			suffix: "gal",
			name: "unit.gallon",
			trueValue: (v) => v * 3.785,
			fromTrueValue: (t) => t / 3.785
		},

		// Temperature
		c: {
			key: "c",
			suffix: "°C",
			name: "unit.celsius",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		f: {
			key: "f",
			suffix: "°F",
			name: "unit.fahrenheit",
			trueValue: (v) => (v - 32) * (5 / 9),
			fromTrueValue: (t) => (t * 9 / 5) + 32
		},
		k: {
			key: "k",
			suffix: "K",
			name: "unit.kelvin",
			trueValue: (v) => v - 273.15,
			fromTrueValue: (t) => t + 273.15
		},

		// Speed
		kmh: {
			key: "kmh",
			suffix: "km/h",
			name: "unit.kmh",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		mps: {
			key: "mps",
			suffix: "m/s",
			name: "unit.mps",
			trueValue: (v) => v * 3.6,
			fromTrueValue: (t) => t / 3.6
		},
		mph: {
			key: "mph",
			suffix: "mph",
			name: "unit.mph",
			trueValue: (v) => v * 1.609,
			fromTrueValue: (t) => t / 1.609
		},
		kt: {
			key: "kt",
			suffix: "kt",
			name: "unit.kt",
			trueValue: (v) => v * 1.852,
			fromTrueValue: (t) => t / 1.852
		},
		mach: {
			key: "mach",
			suffix: "mach",
			name: "unit.mach",
			trueValue: (v) => v * 1225.044,
			fromTrueValue: (t) => t / 1225.044
	},

		// Consumption
		lh: {
			key: "lh",
			suffix: "l/h",
			name: "unit.lh",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		galh: {
			key: "galh",
			suffix: "gal/h",
			name: "unit.galh",
			trueValue: (v) => v * 3.785,
			fromTrueValue: (t) => t / 3.785
		},

		// Angle
		deg: {
			key: "deg",
			suffix: "°",
			name: "unit.deg",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		rad: {
			key: "rad",
			suffix: "rad",
			name: "unit.rad",
			trueValue: (v) => v * 180 / Math.PI,
			fromTrueValue: (t) => t * Math.PI / 180
		},

		// Time
		s: {
			key: "s",
			suffix: "s",
			name: "unit.s",
			trueValue: (v) => v,
			fromTrueValue: (t) => t
		},
		min: {
			key: "min",
			suffix: "min",
			name: "unit.min",
			trueValue: (v) => v * 60,
			fromTrueValue: (t) => t / 60
		},
		h: {
			key: "h",
			suffix: "h",
			name: "unit.h",
			trueValue: (v) => v * 3600,
			fromTrueValue: (t) => t / 3600
		},
		d: {
			key: "d",
			suffix: "d",
			name: "unit.d",
			trueValue: (v) => v * 86400,
			fromTrueValue: (t) => t / 86400
		},
		w: {
			key: "w",
			suffix: "w",
			name: "unit.w",
			trueValue: (v) => v * 604800,
			fromTrueValue: (t) => t / 604800
		},
		mo: {
			key: "mo",
			suffix: "mo",
			name: "unit.mo",
			trueValue: (v) => v * 2629800,
			fromTrueValue: (t) => t / 2629800
		},
		y: {
			key: "y",
			suffix: "y",
			name: "unit.y",
			trueValue: (v) => v * 31557600,
			fromTrueValue: (t) => t / 31557600
		}
};

/**
 * @type Units
 * */
let units = {
	all: _allUnits,
	pressure: {
		mb: _allUnits["mb"],
		bar: _allUnits["bar"],
		hPa: _allUnits["hPa"],
		Pa: _allUnits["Pa"],
		inHg: _allUnits["inHg"],
		mmHg: _allUnits["mmHg"]
	},
	distance: {
		m: _allUnits["m"],
		cm: _allUnits["cm"],
		in: _allUnits["in"],
		ft: _allUnits["ft"],
		yd: _allUnits["yd"],
		mi: _allUnits["mi"],
		nmi: _allUnits["nmi"],
		km: _allUnits["km"],
		fl: _allUnits["fl"]
	},
	mass: {
		g: _allUnits["g"],
		kg: _allUnits["kg"],
		t: _allUnits["t"],
		lbs: _allUnits["lbs"]
	},
	volume: {
		l: _allUnits["l"],
		gal: _allUnits["gal"]
	},
	temperature: {
		c: _allUnits["c"],
		f: _allUnits["f"],
		k: _allUnits["k"]
	},
	speed: {
		kmh: _allUnits["kmh"],
		mps: _allUnits["mps"],
		mph: _allUnits["mph"],
		kt: _allUnits["kt"]
	},
	consumption: {
		lh: _allUnits["lh"],
		galh: _allUnits["galh"]
	},
	angle: {
		deg: _allUnits["deg"],
		rad: _allUnits["rad"]
	},
	time: {
		s: _allUnits["s"],
		min: _allUnits["min"],
		h: _allUnits["h"],
		d: _allUnits["d"],
		w: _allUnits["w"],
		mo: _allUnits["mo"],
		y: _allUnits["y"]
	}
};

units.getUnit = function(key) {
	return this.all[key] || null;
};

units.getUnitOrDefault = function(key) {
	return this.all[key] || {
		key: key,
		suffix: key,
		name: key,
		trueValue: (v) => v,
		fromTrueValue: (t) => t
	};
};

units.convert = function(value, from, to, roundTo) {
	if (from === to) {
		return value;
	}
	const fromUnit = this.getUnit(from);
	const toUnit = this.getUnit(to);

	if (fromUnit && toUnit) {
		value = toUnit.fromTrueValue(fromUnit.trueValue(value));
	}

	if(roundTo !== undefined) {
		return Math.round(value * Math.pow(10, roundTo)) / Math.pow(10, roundTo);
	}

	return value;
};

units.convertFromTrueValue = function(value, from) {
	const fromUnit = this.getUnit(from);
	if (fromUnit) {
		return fromUnit.fromTrueValue(value);
	}
	return value;
};

units.convertToTrueValue = function(value, to) {
	const toUnit = this.getUnit(to);
	if (toUnit) {
		return toUnit.trueValue(value);
	}
	return value;
};
units.getVolumeUnitFromConsumptionUnit = function(unit) {
	const units = {
		lh: "l",
		galh: "gal"
	};
	return units[unit] || null;
};
units.getDistanceUnitFromSpeedUnit = function(unit) {
	const units = {
		kmh: "km",
		mps: "m",
		mph: "mi",
		kt: "nmi"
	};
	return units[unit] || null;
};

units.getTimeUnitFromSpeedUnit = function(unit) {
	const units = {
		kmh: "h",
		mps: "s",
		mph: "h",
		kt: "h"
	};
	return units[unit] || null;
}


//**********************************************************************************************************************

const UnitsPlugin = {
	install(Vue, options) {
		window.units = units;
		Vue.prototype.$units = units;
	}
};

Vue.use(UnitsPlugin);
Vue.filter("unitName", function(value) {
	return t(units.getUnitOrDefault(value).name);
});

Vue.filter("unitSuffix", function(value) {
	return units.getUnitOrDefault(value).suffix;
});

Vue.filter('convert', function(value, from, to) {
	if(!from || !to) return value;
	return units.convert(value, from, to);
});

if (typeof window !== "undefined" && window.Vue) {
	window.Vue.use(UnitsPlugin);
}

export default units;


/**
 * Filters an object by keys
 * @param {Record<string, any>} obj
 * @param {string} keys
 */
function filterKeys(obj, ...keys) {
	return _.filter(obj, (value, key) => keys.includes(key));
}
