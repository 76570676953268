<template>
	<input
		:id="id"
		type="number"
		:value="text"
		@change="change"
		:pattern="decimal ? '[-+]?[0-9]*[.,]?[0-9]+' : '[0-9.]*'"
		@input="$emit('input', $event.target.value)"
		@keypress="isNumber($event)"
		style="min-width: 60px"
	/>
</template>

<script>

export default {
	name: 'NumberInput',
	data() {
		return {
			text: ''
		};
	},
	props: [ 'id', 'value', 'msgs', 'required', 'decimal', 'minus' ],
	mounted() {
		if(typeof this.value == "number") {
				this.text = this.value.toString()
		}else {
			this.text = this.value;
		}

	},
	watch: {
		value(v) {
			if(typeof v == "number") {
				this.text = v.toString()
			}else {
				this.text = v;
			}
		}
	},
	methods: {
		input(event) {
			this.text = event.target.value;
		},
		change(event) {
			let val = event.target.value.trim();

			if(!val && this.required) {
				event.target.value = val;
				this.text = val;
				this.$emit('change', parseFloat(val));
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}]);
				return;
			}

			event.target.value = val;
			this.text = val;
			this.$emit('change', parseFloat(val));
			this.$set(this.msgs, this.id, []);
		},
		isNumber(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			let char = String.fromCharCode(charCode);

			if(!this.text) this.text = "";

			if(/\d/.test(char)) return true;
			if(this.decimal && char == ".") return true;
			if(this.minus && char == "-") return true;

			evt.preventDefault();
		}
	},
	emits: ['input', 'change']
}
</script>
