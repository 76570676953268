<template>
	<div class="bundle" >
		<div class="bundle-body">
			<div class="bundle-image">
				<img v-if="bundle.image" v-bind:src="bundle.image" alt="icon" />
			</div>
			<div class="bundle-title">
				<div>
					<font-awesome-icon icon="map"/>
					{{ formattedSize }}
				</div>
				<div class="bundle-name">
					{{ name }}
				</div>
			</div>
		</div>
		<div>
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'OfflineLayer',
	computed: {
		formattedSize() {
			const size = this.bundle.size;
			const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
			return +((size / Math.pow(1024, i)).toFixed(2)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
		},
		name() {
			return this.bundle.names[this.$i18n.locale];
		}
	},
	props: {
		bundle: Object,
	}
}
</script>
<style>

.bundle{
	display: flex;;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #f8f9fa;
	border-radius: 1rem;
	width: 100%;
	padding: 1rem 2rem;
	gap: 2rem;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.bundle-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
}

.bundle-image {
	width: 48px;
	height: 48px;
	border-radius: 0.5rem;
	overflow: hidden;
	border: 1px solid #ccc;
}

.bundle-image img {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 630px) {
	.bundle {
		flex-direction: column;
	}
}
</style>
