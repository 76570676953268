import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getParameterByName(name, url = window.location.href) {
	name = name.replace(/[[\]]/g, '\\$&');
	let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const store = new Vuex.Store({

	state: {
		loggedInEmail: window.localStorage.getItem('loggedInEmail'),
		loggedInUserToken: window.localStorage.getItem('loggedInUserToken'),
		loggedInUserDataState: (() => {
			let d = window.localStorage.getItem('loggedInUserData');
			if(d) {
				return JSON.parse(d)
			}
			return null;
		})(),
		latLng: null,
		planes: [],
		planeToSave: null,
		currentGPS: { lat: 0, lng: 0, alt: 0, course: 0, speed: 0, time: '' },
    startGPS: { lat: 0, lng: 0, alt: 0, course: 0, speed: 0 },
		noInternet: false,
		tempAirspaces: (() => {
			let d = window.localStorage.getItem('tempAirspaces');
			if(d) {
				return JSON.parse(d);
			}
			return [];
		})(),
		airspaces: (() => {
			let d = window.localStorage.getItem('airspaces');
			if(d) {
				return JSON.parse(d);
			}
			return [];
		})(),
		airspaceUpdatedAt: (() => {
			let d = window.localStorage.getItem('airspaceUpdatedAt');
			if(d) {
				return JSON.parse(d);
			}
			return null;
		})(),
		gpsPermission: getParameterByName('gpsPermission')
	},
	getters: {
		/** @returns {string} */
		loggedInEmail: state => {
			return state.loggedInEmail;
		},
		/** @returns {string} */
		loggedInUserToken: state => {
			return state.loggedInUserToken;
		},
		/** @returns {User} */
		getLoggedInUserData: state => {
			return state.loggedInUserDataState;
		},
		getLatLng: state => {
			return state.latLng;
		},
		getCurrentGPS: state => {
			return state.currentGPS;
		},
    getStartGPS: state => {
			return state.startGPS;
		},
		getPlanes: state => {
			return state.planes
		},
		getPlaneToSave: state => {
			return state.planeToSave;
		},
		getNoInternet: state => {
			return state.noInternet;
		},
		getTempAirspaces: state => {
			return state.tempAirspaces;
		},
		getAirspaces: state => {
			return state.airspaces;
		},
		getAirspaceUpdatedAt: state => {
			return state.airspaceUpdatedAt;
		},

		/** @returns {('DISABLED'|'FOREGROUND'|'BACKGROUND'|null)} */
		getGpsPermission: state => {
			return state.gpsPermission;
		}
	},
	mutations: {
		storeLoggedInEmail: (state, payload) => {
			state.loggedInEmail = payload;
			if(payload) {
				window.consentedStorage.setItem('loggedInEmail', payload);
			}
			else {
				window.consentedStorage.removeItem('loggedInEmail');
			}
		},
		storeLoggedInUserToken: (state, payload) => {
			state.loggedInUserToken = payload;
			if(payload) {
				window.consentedStorage.setItem('loggedInUserToken', payload);
			}
			else {
				window.consentedStorage.removeItem('loggedInUserToken');
			}
		},
		storeLoggedInUserData: (state, payload) => {
			state.loggedInUserDataState = payload;
			if(payload) {
				window.consentedStorage.setItem('loggedInUserData', JSON.stringify(payload));
			}
			else {
				window.consentedStorage.removeItem('loggedInUserData');
			}
		},
		storeCurrentGPS: (state, payload) => {
			state.currentGPS = payload;
		},
    storeStartGPS: (state, payload) => {
			state.startGPS = payload;
		},
		storePolyLineClickEvent: (state, payload) => {
			state.latLng = payload;
		},
		storePlanes: (state, payload) => {
			state.planes = payload;
		},
		storePlaneToSave: (state, payload) => {
			state.planeToSave = payload;
		},
		storeNoInternet: (state, payload) => {
			state.noInternet = payload;
		},
		storeTempAirspaces: (state, payload) => {
			state.tempAirspaces = payload;
			window.consentedStorage.setItem('tempAirspaces', JSON.stringify(payload));
		},
		storeAirspaces: (state, payload) => {
			state.airspaces = payload;
			state.airspaceUpdatedAt = new Date().getTime().toString();
			window.consentedStorage.setItem('airspaces', JSON.stringify(payload));
			window.consentedStorage.setItem("airspaceUpdatedAt", new Date().getTime().toString());
		},
		storeGpsPermission: (state, payload) => {
			state.gpsPermission = payload;
		}
	},
	actions: {
		storeLoggedInEmail: (context, payload) => {
			context.commit('storeLoggedInEmail', payload);
		},
		storeLoggedInUserToken: (context, payload) => {
			context.commit('storeLoggedInUserToken', payload);
		},
		storeLoggedInUserData: (context, payload) => {
			context.commit('storeLoggedInUserData', payload);
		},
		storeCurrentGPS: (context, payload) => {
			context.commit('storeCurrentGPS', payload);
		},
    storeStartGPS: (context, payload) => {
			context.commit('storeStartGPS', payload);
		},
		storePolyLineClickEvent: (context, payload) => {
			context.commit('storePolyLineClickEvent', payload);
		},
		storePlanes: (context, payload) => {
			context.commit('storePlanes', payload)
		},
		storePlaneToSave: (context, payload) => {
			context.commit('storePlaneToSave', payload);
		},
		storeNoInternet: (context, payload) => {
			context.commit('storeNoInternet', payload);
		},
		storeTempAirspaces: (context, payload) => {
			context.commit('storeTempAirspaces', payload);
		},
		storeAirspaces: (context, payload) => {
			context.commit('storeAirspaces', payload);
		},
		storeGpsPermission: (context, payload) => {
			context.commit('storeGpsPermission', payload);
		},
	}
});
