<template>
	<div>
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
				style="margin: auto; background-image: none; display: block; shape-rendering: auto;" width="100px" class="loading"
				height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<g>
				<circle stroke-linecap="round" fill="none" stroke-dasharray="50.26548245743669 50.26548245743669"
								stroke="#0086ff" stroke-width="8" r="32" cy="50" cx="50" transform="matrix(1,0,0,1,0,0)"
								style="fill:none;stroke:rgb(0, 134, 255);transform:matrix(1, 0, 0, 1, 0, 0);;animation:none"></circle>
				<g></g>
			</g>
			</svg>
	</div>
</template>

<script>

export default {
	name: 'LoadingSpinner',
}
</script>
<style>

.loading {
	animation: loading 0.8s linear infinite;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


</style>
