<template>
	<input
		:id="id"
		type="text"
		:value="text"
		:maxlength="maxlength"
		@change="change"
		@keypress="keypress"
		@input="$emit('input', $event.target.value)"
		@blur="blur"
	/>
</template>

<script>

export default {
	name: 'TextInput',
	data() {
		return {
			text: ''
		};
	},
	props: {
		id: String,
		value: String,
		msgs: Object,
		maxlength: String,
		required: Boolean
	},
	mounted() {
		this.text = this.value;
	},
	watch: {
		value(v) {
			this.text = v;
		}
	},
	methods: {
		blur(event) {
			this.$emit("blur", event)
		},
		keypress(event) {
			this.$emit("keypress", event)
		},
		input(event) {
			this.text = event.target.value;
		},
		change(event) {
			let val = event.target.value.trim();

			if(!val && this.required) {
				event.target.value = val;
				this.text = val;
				this.$emit('change', val);
				if(this.msgs) {
					this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}]);
				}
				return;
			}

			event.target.value = val;
			this.text = val;
			this.$emit('change', val);
			if(this.msgs) {
				this.$set(this.msgs, this.id, []);
			}
		}
	},
	emits: ['input', 'change', 'keypress', 'blur']
}
</script>
