<template>
	<div class="px-3 pt-4" style="max-height: 100vh; overflow-y: auto;">
		<div class="modal-body">
			<font-awesome-icon class="m-0 text-danger" size="4x" :icon="['fas', 'exclamation-triangle']" />
			<h4 class="mt-3"> {{title}} </h4>
			<div class="mt-1 text-center" v-if="message" v-html="message">
			</div>

			<div class="modal-actions">
				<button class="btn btn-secondary w-100" @click="$emit('close', 0)">
					<font-awesome-icon class="m-0" :icon="['fas', 'times']" />
					{{$t('confirm.cancel')}}
				</button>
				<button class="btn btn-success w-100" @click="$emit('close', 1)">
					<font-awesome-icon class="m-0" :icon="['fas', 'check']" />
					{{$t('confirm.ok')}}
				</button>
			</div>
		</div>
	</div>
</template>

<script>



export default {
	name: "ConfirmModal",
	emits: ['close'],
	props: {
		title: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			default: null,
		},
	},
}
</script>

<style scoped>
.close-btn {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.modal-actions {
	display: flex;
	width: 100%;
	justify-content: stretch;
	//justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-top: 2rem;
}

.modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}


</style>

