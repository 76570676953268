
/**
 * @type Map<string, string>
 */
const helpers = {
	"map": 'helpMenu.map',
	"start-flight": "helpMenu.startFlight",
	"flying-map": 'helpMenu.map',
	"weather": 'helpMenu.weather',
	"notam": "helpMenu.notam",
	"route-planning": "helpMenu.route-planning",
	"route-planning-element": "helpMenu.route-planning",
	"unit-settings": "helpMenu.unitSettings",
	"map-settings": "helpMenu.mapSettings",
	"traffic-settings": "helpMenu.trafficSettings",
	"airspace-settings": "helpMenu.airspaceSettings",
	"plane-settings": "helpMenu.planeSettings",
	"fuel-settings": "helpMenu.fuelSettings",
	"route-settings": "helpMenu.routeSettings",
	"alternates-settings": "helpMenu.alternatesSettings",
	"navlog-settings": "helpMenu.navlogSettings",
	"crew-settings": "helpMenu.crewSettings",
	"densityAltitude": "helpMenu.densityAltitude",
	"cog": "helpMenu.cog",
	"flight-plan": "helpMenu.flightPlan",
	"flights": "helpMenu.flights",
	"planes": "helpMenu.planes",
}

export default {
	helpers
}
