<template>
	<div class="d-flex justify-content-center flex-column align-items-center mt-5 gap-1">
		<font-awesome-icon icon="exclamation-triangle" class="text-4xl text-danger" />
		<h6 class="py-2 px-4 text-danger font-weight-bold ">
			{{$t('server.error')}}
		</h6>
		<button class="btn btn-primary" @click="$emit('retry')">
			<font-awesome-icon icon="redo" />
			{{$t('server.retry')}}
		</button>
	</div>
</template>

<script>

export default {
	name: 'ServerError',
	emits: ['retry']
}
</script>
