<template>
  <div class="sidemenu">

    <ul class="sidemenu-list">
      <template>

        <template v-if="!loggedInUserData">
<!--					<li class="sidemenu-item" v-if="!mobile" @click="goToPilotNetPro(false)" role="button" @mouseover="color = 'white'" @mouseleave="color = 'black'">-->
<!--						<a class="d-flex align-items-center">-->
<!--							<pilot-net-logo :color="color" only-icon width="17.5px" styleClass="ml-1" iconStyle="margin-right: .7rem;"></pilot-net-logo>-->
<!--							<span class="menu-title show-title">{{$t('menu.pilotnet-pro')}}</span>-->
<!--						</a>-->
<!--					</li>-->
					<MainMenuItem :title="$t('pageTitle.map')" to="/" @click="$emit('click')" icon="map"/>
					<MainMenuItem :title="$t('menu.weather')" to="/weather" @click="$emit('click')" icon="cloud-sun"/>
          <MainMenuItem :title="$t('menu.login')" to="/login" @click="$emit('click')" icon="sign-in-alt"/>
          <MainMenuItem :title="$t('menu.register')" to="/register" @click="$emit('click')" icon="user-plus"/>
          <MainMenuItem :title="$t('menu.forgottenPassword')" to="/forgotten-password" @click="$emit('click')" icon="key"/>
					<MainMenuItem :title="$t('menu.help')" to="/help" @click="$emit('click')" icon="question-circle"/>
        </template>
        <template v-else>
					<template v-if="mobile">
						<MainMenuItem :title="$t('pageTitle.start-flight')" to="/start-flight" @click="$emit('click')" icon="plane-departure" />
						<MainMenuItem :title="$t('menu.notes')" to="/notes" @click="$emit('click')" icon="sticky-note"/>
						<MainMenuItem :title="$t('menu.weather')" to="/weather" @click="$emit('click')" icon="cloud-sun"/>
						<MainMenuItem :title="$t('menu.myfiles')" to="/myfiles" @click="$emit('click')" icon="folder-open"/>
						<MainMenuItem :title="$t('menu.flights')" to="/flights" @click="$emit('click')" icon="plane-arrival" />
						<MainMenuItem :title="$t('pageTitle.map')" to="/" @click="$emit('click')" icon="map"/>
						<MainMenuItem :title="$t('menu.cog')" to="/cog" @click="$emit('click')" icon="balance-scale-left"/>
						<MainMenuItem :title="$t('menu.converter')" to="/converter" @click="$emit('click')" icon="balance-scale-left"/>
						<MainMenuItem :title="$t('menu.route-planning')" to="/route-planning" @click="$emit('click')" icon="route"/>
						<MainMenuItem :title="$t('menu.notam')" to="/notam" @click="$emit('click')" icon="newspaper"/>
						<MainMenuItem :title="$t('menu.flightPlan')" to="/flight-plan" @click="$emit('click')" icon="paper-plane" />
						<MainMenuItem :title="$t('menu.briefingReport')" to="/briefing-report" @click="$emit('click')" icon="print" />
						<MainMenuItem :title="$t('menu.planes')" to="/planes" @click="$emit('click')" icon="plane" />
						<MainMenuItem :title="$t('menu.help')" to="/help" @click="$emit('click')" icon="question-circle"/>
						<MainMenuItem :title="$t('menu.me')" to="/me" @click="$emit('click')" icon="user"/>
<!--						<li class="sidemenu-item" @click="goToPilotNetPro(true)" role="button" @mouseover="color = 'white'" @mouseleave="color = 'black'">-->
<!--							<a class="d-flex align-items-center">-->
<!--								<pilot-net-logo :color="color" only-icon width="17.5px" styleClass="ml-1" iconStyle="margin-right: .7rem;"></pilot-net-logo>-->
<!--								<span class="menu-title show-title">{{$t('menu.pilotnet-pro')}}</span>-->
<!--							</a>-->
<!--						</li>-->
					</template>
					<template v-else>
<!--						<li class="sidemenu-item" @click="goToPilotNetPro(true)" role="button" @mouseover="color = 'white'" @mouseleave="color = 'black'">-->
<!--							<a class="d-flex align-items-center">-->
<!--								<pilot-net-logo :color="color" only-icon width="17.5px" styleClass="ml-1" iconStyle="margin-right: .7rem;"></pilot-net-logo>-->
<!--								<span class="menu-title show-title">{{$t('menu.pilotnet-pro')}}</span>-->
<!--							</a>-->
<!--						</li>-->
						<MainMenuItem :title="$t('pageTitle.map')" to="/" @click="$emit('click')" icon="map"/>
						<MainMenuItem :title="$t('pageTitle.start-flight')" to="/start-flight" @click="$emit('click')" icon="plane-departure" v-if="local" />
						<MainMenuItem :title="$t('menu.weather')" to="/weather" @click="$emit('click')" icon="cloud-sun"/>
						<MainMenuItem :title="$t('menu.notam')" to="/notam" @click="$emit('click')" icon="newspaper"/>
						<MainMenuItem :title="$t('menu.route-planning')" to="/route-planning" @click="$emit('click')" icon="route"/>
						<MainMenuItem :title="$t('menu.cog')" to="/cog" @click="$emit('click')" icon="balance-scale-left"/>
						<MainMenuItem :title="$t('menu.flightPlan')" to="/flight-plan" @click="$emit('click')" icon="paper-plane" />
						<MainMenuItem :title="$t('menu.notes')" to="/notes" @click="$emit('click')" icon="sticky-note" v-if="local"/>
						<MainMenuItem :title="$t('menu.briefingReport')" to="/briefing-report" @click="$emit('click')" icon="print" />
						<MainMenuItem :title="$t('menu.converter')" to="/converter" @click="$emit('click')" icon="balance-scale-left"/>
						<MainMenuItem :title="$t('menu.flights')" to="/flights" @click="$emit('click')" icon="plane-arrival" />
						<MainMenuItem :title="$t('menu.planes')" to="/planes" @click="$emit('click')" icon="plane" />
						<MainMenuItem :title="$t('menu.myfiles')" to="/myfiles" @click="$emit('click')" icon="folder-open"/>
						<MainMenuItem :title="$t('menu.help')" to="/help" @click="$emit('click')" icon="question-circle"/>
						<MainMenuItem :title="$t('menu.me')" to="/me" @click="$emit('click')" icon="user"/>
					</template>
        </template>
				<MainMenuItem :title="$t('menu.contact')" to="/contact" @click="$emit('click')" icon="address-card"/>
      </template>
      <li class="sidemenu-item" v-if="loggedInUserData">
        <a href="javascript:void(0)" @click="$emit('logout'); $emit('click');">
          <font-awesome-icon icon="sign-out-alt" class="fa-lg fa-fw mr-2"/>
          <span class="menu-title show-title"> {{ $t('logout.logoutButton') }}</span>
        </a>
      </li>

    </ul>
  </div>
</template>

<script>
import MainMenuItem from './MainMenuItem.vue'
import PilotNetLogo from "@/components/PilotNetLogo.vue";

export default {
  name: 'MainMenu',
  data() {
    return {
      isMenuOpen: true,
			color: "black",
    };
  },
  methods: {
    toggleIsMenuOpen(){
      this.isMenuOpen = !this.isMenuOpen;
    },
    setIsMenuOpen(isMenuOpen){
      this.isMenuOpen = isMenuOpen;
    },
    isServiceProvider() {
      if (this.loggedInUserData.types) {
        let res = this.loggedInUserData.types.some(t => t == 'SERVICE_PROVIDER')
        return res
      }
      return false
    },
		goToPilotNetProMobile() {
			window.loadAppLink('r2gflightserver://go-to-pilotnet-pro')
		},
		goToPilotNetPro(login) {
			if(!login) {
				if (window.location.hostname.includes('teszt') || window.location.href.includes('testMode')) {
					window.location.href = 'https://teszt.pro.pilotnet.hu/#';
				}
				else {
					window.location.href = 'https://pro.pilotnet.hu/#';
				}
			}
			else {
				//if(!this.mobile)
					window.location.href = this.$rest.proBaseUrl + "/user/login?redirectTo=/&username=" + this.loggedInUserData.username + "&usertoken=" + this.$store.getters.loggedInUserToken;
				//else
					//window.loadAppLink("r2gflightserver://go-to-pilotnet-pro?username=" + this.loggedInUserData.username + "&usertoken=" + this.$store.getters.loggedInUserToken);
			}
		}
  },
  computed: {
    loggedInUserData() {
      return this.$store.getters.getLoggedInUserData
    },
    mobile() {
      return 'ios' === window.platform || 'android' === window.platform;
    },
    local() {
      return window.location.href.indexOf('localhost') >= 0 || window.location.href.indexOf('local.flight') >= 0 || window.location.href.indexOf('192.168.') >= 0;
    }
  },
  components: {
		// PilotNetLogo,
    MainMenuItem
  }
  ,
  emits: ['logout', 'click', 'toggleMenu']
}
</script>

<style>
.sidemenu {
  height: 100vh;
  overflow-y: auto;
}

.sidemenu-list {
  padding-left: 0;
  list-style-type: none;
}

.sidemenu-item {
  border-bottom: 1px solid #ccc;
}

.sidemenu-item a {
  color: #000000;
  display: block;
  padding: 20px;
  padding-right: 10px;
}

.menu-title {
  display: inline-block;
}

.show-title {
  display: inline-block;
}

.hide-title {
  display: none;
}

.sidemenu-item:active,
.sidemenu-item:hover {
	text-decoration: none;
}

.sidemenu-item:hover,
.sidemenu-item:active {
	color: #ffffff;
	background: #0468c2;
}

.sidemenu-item.active a,
.sidemenu-item:hover a {
  color: #ffffff;
  text-decoration: none;
}

.sidemenu-item.active {
  background-color: #0086FF;
  box-shadow: inset 0px 0 8px rgba(0, 0, 0, 0.5);
}
</style>
