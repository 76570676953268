<template>
	<div id="consentPopupBackground">
		<div class="consentPopup">
			<h2 class="title">
				{{ $t('consent.title') }}
			</h2>
			<div>
				<p v-html="$t('consent.description')"></p>
			</div>
			<div class="customize" v-if="customize">
				<div>
					<Checkbox id="functionality_storage" v-model="functionality_storage" :binary="true" disabled="disabled" />
					<label for="functionality_storage" class="form-check-label">{{$t('consent.functionality')}}</label>
				</div>
				<div>
					<Checkbox id="personalization_storage" v-model="personalization_storage" :binary="true" />
					<label for="personalization_storage" class="form-check-label">{{$t("consent.personalization")}}</label>
				</div>
				<div>
					<Checkbox id="ad_storage" v-model="ad_storage" :binary="true" />
					<label for="ad_storage" class="form-check-label">{{ $t("consent.ad-storage") }}</label>
				</div>
				<div>
					<Checkbox id="analytics_storage" v-model="analytics_storage" :binary="true" />
					<label for="analytics_storage" class="form-check-label">{{ $t("consent.analytics_storage") }}</label>
				</div>
				<div>
					<Checkbox id="security_storage" v-model="security_storage" :binary="true" />
					<label for="security_storage" class="form-check-label">{{ $t('consent.security') }}</label>
				</div>
			</div>
			<div class="buttons" v-if="customize">
				<button class="btn btn-link" @click="customize = !customize">{{ $t('consent.cancel') }}</button>
				<div>
					<button class="btn btn-success" @click="acceptAll">{{$t('consent.acceptAll')}}</button>
					<button class="btn btn-primary" @click="submit">{{$t('consent.save')}}</button>
				</div>
			</div>
			<div class="buttons" v-if="!customize">
				<button class="btn btn-link" @click="customize = !customize">{{ $t('consent.customize') }}</button>
				<div>
					<button class="btn btn-light" @click="rejectAll">{{$t('consent.reject')}}</button>
					<button class="btn btn-success" @click="acceptAll">{{$t('consent.accept')}}</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import _ from "lodash";
export default {
	name: 'ConstentPopup',
	components: { Checkbox },
	emits: ['close'],
	data() {
		return {
			'customize': false,
			'ad_storage': false,
			'analytics_storage': false,
			'personalization_storage': false,
			'functionality_storage': true,
			'security_storage': false,
		}
	},
	methods: {
		rejectAll(){
			this.ad_storage = false;
			this.analytics_storage = false;
			this.personalization_storage = false;
			this.functionality_storage = false;
			this.security_storage = false;
			this.submit()
		},
		acceptAll(){
			this.ad_storage = true;
			this.analytics_storage = true;
			this.personalization_storage = true;
			this.functionality_storage = true;
			this.security_storage = true;
			this.submit()
		},
		submit(){
			window.consentMode = {
				'ad_storage': this.ad_storage,
				'analytics_storage': this.analytics_storage,
				'personalization_storage': this.personalization_storage,
				'functionality_storage': this.functionality_storage,
				'security_storage': this.security_storage,
			}

			window.consentedStorage.setItem('consentMode', JSON.stringify(window.consentMode));

			window.gtag('consent', 'update', _.mapValues(window.consentMode, (value) => {
				return value ? 'granted' : 'denied';
			}));

			this.$emit('close');
		}
	}
}
</script>

<style>

#consentPopupBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}


.consentPopup {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	max-width: 500px;
}

.consentPopup .title {
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 20px;
}

.consentPopup p {
	font-size: 13px;
}

.consentPopup .customize {
	margin-bottom: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}

.consentPopup .customize > div {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.consentPopup button {
	margin-right: 10px;
}


.buttons {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 500px) {
	.consentPopup .customize {
		grid-template-columns: 1fr;
	}

	.consentPopup .buttons {
		flex-direction: column-reverse;
		gap: 10px;
	}

	.consentPopup .buttons > div {
		display: flex;
		flex-direction: column-reverse;
		gap: 10px;
	}
}




</style>
