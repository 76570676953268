<template>
	<div class="file" @click="open">
		<div class="file-body">
			<div class="file-icon">
				<font-awesome-icon icon="file" v-if="!file.directory" />
				<font-awesome-icon icon="folder" v-else />
			</div>
			<div class="file-name">{{ file.name }}</div>
			<div>
				<font-awesome-icon icon="download" @click.stop="open()" v-if="!file.directory" />
			</div>
			<div class="file-size" v-if="!file.directory">
				{{ file.size | formatBytes }}
			</div>
		</div>
		<div class="delete" v-if="!mobile">
			<font-awesome-icon icon="trash" class="text-danger" @click.stop="remove()" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'VFile',
	emits: ['reload', 'setPath'],
	props: {
		vfile: Object,
		mobile: Boolean
	},
	data() {
		return {
			/** @type {VirtualFile} */
			file: null,
		}
	},
	created() {
		this.file = this.vfile;
	},
	methods: {
		open() {
			if(this.file.directory) {
				return this.$emit('setPath', this.file.path)
			}
			if(!this.mobile) {
				return window.open(this.$rest.getFileUrl(this.file.path), '_blank');
			}

			window.loadAppLink('r2gflightserver://open-file?path=' + this.file.path)
		},
		async remove() {
			if(!await this.$confirm(this.$t('myFiles.deleteMsg'))) return;
			await this.$rest.deleteFile(this.file.path);
			this.$emit('reload');
		},
	},
}
</script>

<style scoped>
.file {
	display: flex;
	gap: 1rem;
	justify-content: center;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
	align-items: center;
	cursor: pointer;
}

.file:hover {
	background: #f0f0f0;
}

.file-icon {
	font-size: 1.2rem;
	color: var(--primary);
}

.file-name {
	margin:0;
	font-size: 0.8rem;
	text-align:center;
	font-weight: bold;
	white-space: pre-wrap;
	word-break: break-all;
}

.file-body {
	display: flex;
	gap: 1rem;
	align-items: center;
	flex: 1;
}

.file-size {
	font-size: 0.8rem;
	color: #666;
}


.delete {
	cursor: pointer;
}
.delete:hover {
	color: darkred;
	scale: 1.1;
}



</style>
