import $ from "jquery";
import moment from "moment";
import units from "@/plugins/units.js";

export function scrollToAnchor(aid, offset, time) {
  setTimeout(() => {
    if (typeof offset === 'undefined') {
      offset = 0;
    }
    let stickyNav = $('.site-nav.sticky-top');
    if (stickyNav.length > 0) {
      offset += stickyNav.height();
    }
    if (typeof time === 'undefined') {
      time = 500;
    }
    let aTag = $("a[name='" + aid + "']");
    let o = aTag.offset();
    if (o) {
      if (time == 0) {
        window.scrollTo(0, o.top - offset);
      } else {
        $('html,body').animate({scrollTop: o.top - offset}, time);
      }
    }
  }, 0);
}

export function scrollToTop(time) {
  setTimeout(() => {
    if (typeof time === 'undefined') {
      time = 500;
    }
		const mainArea = $('.mainArea')[0];
		if(!mainArea) {
			return;
		}
    if (time === 0) {
      mainArea.scrollTo(0, 0);
    } else {
      $(mainArea).animate({scrollTop: 0}, time);
    }
  }, 0);
}


/**
 * @param {number} n
 * @returns {number}
 */
function truncate(n) {
  return n > 0 ? Math.floor(n) : Math.ceil(n);
}

/**
 * Convert decimal degrees to degrees, minutes, seconds
 * @param {number} dd
 * @param {('N'|'W'|'S'|'E')} hemisphere
 * @returns {[number,number,number,string]}
 */
function getDMSArray(dd, hemisphere) {
  const absDD = Math.abs(dd);
  let degrees = truncate(absDD);
  const minutes = truncate((absDD - degrees) * 60);
  const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);
  if (hemisphere === 'W' || hemisphere === 'E' && degrees.toString().length === 2) {
    degrees = '0' + degrees;
  }
  return [degrees, minutes, Number(seconds), hemisphere];
}

export const STORAGE_KEY = {
  ROUTE_PLANE: 'route-plans',
  NAV_OBJECT: 'nav-object',
  SELECTED_ROUTE_PLANE: 'selected-route-plan',
  DIRECT_TO_KEY: 'direct-to-list'
};


/**
 * @param {number} nr
 * @param {number} base
 * @returns {string|*}
 * @constructor
 */
export const ZERO_PAD = (nr, base= 2) => {
	return String(nr).padStart(base, '0');
}


/**
 * @param {number} value
 * @returns {number}
 */
export const TRACK_CEIL = (value) => {
  if (value < 0) {
    return ZERO_PAD(Math.ceil(360 + value), 3);
  }
  return ZERO_PAD(Math.ceil(value), 3);
}

/**
 * @deprecated Use window.consentedStorage instead
 */
export const LOCAL_STORAGE_SERVICE = {
  getItem: (key, fallbackObject) => {
    const item = window.consentedStorage.getItem(key);
    if (item == null) {
      return fallbackObject;
    }
    return JSON.parse(item);
  },
  setItem: (key, object) => {
    window.consentedStorage.setItem(key, JSON.stringify(object));
  },
  removeItem: (key) => {
    window.consentedStorage.removeItem(key);
  },
  clear: () => {
    window.consentedStorage.clear();
  },
  has: (key) => {
    return window.consentedStorage.getItem(key) != null;
  }
}

export const HELPER_FUNCTIONS = {

	/**
		 * @param {RoutePlanElement} element
		 * @returns {[number, number]}
		 */
	getStartLatLong(element){
		return [element.startLatitude, element.startLongitude];
	},

	/**
	 * @param {RoutePlanElement} element
	 * @returns {[number, number]}
	 */
	getEndLatLong(element){
		return [element.endLatitude, element.endLongitude];
	},

	/**
	 * @param {NavObjectPayload} navObject
	 * @returns {LatLong}
	 */
	navObjectToLatLng(navObject) {
		return {
			lat: navObject.latitude,
			lng: navObject.longitude,
		};
	},
  backEndDateFormat: (date) => {
    if (typeof date == "string") {
      date = new Date(date);
    }
    return moment(date).format('YYYY-MM-DD');
  },
  backEndDateTimeFormat: (date) => {
    if (typeof date == "string") {
      date = new Date(date);
    }
    return moment(date).format();
  },
  frontEndDateFormat: (date) => {
    if (typeof date == "string") {
      date = new Date(date);
    }
    return moment(date).format('YYYY.MM.DD');
  },
}

export const READ_JSON_FILE = (filePath) => {
  let request = new XMLHttpRequest();
  request.open("GET", filePath, false);
  request.send(null)
  return JSON.parse(request.responseText);
}

export const HU_TO_EN = (str) => {

  const ekezet = ["Ö", "ö", "Ü", "ü", "Ó", "ó", "O", "o", "Ú", "ú", "Á", "á", "U", "u", "É", "é", "Í", "í",
    "/", "ő", "Ű", "ű", "Ő", "Ä",
    " ", ",", "(", ")", "{", "}",
    ".", "@", "?", "#", '"', "'"];
  const nekezet = ["O", "o", "U", "u", "O", "o", "O", "o", "U", "u", "A", "a", "U", "u", "E", "e", "I", "i",
    "", "o", "U", "u", "O", "A",
    "-", "-", "", "", "", "",
    "", "", "", "", "", ""];

  const map = {};
  ekezet.forEach((el, index) => {
    map[el] = nekezet[index];
  })
  return str.replace(/[^A-Za-z0-9]/g, (ch) => {
    return map[ch] || ch;
  });
}

export const ROUTE_PLAN_CALCULATOR = {
  DEFAULT_DECLINATION: -5,

	/**
	 * @param {number} dd
	 * @returns {`${string}${number}°${number}'`}
	 * @constructor
	 */
  GET_DMS_TO_LNG: (dd) => {
    let hemisphere = dd < 0 ? "W" : "E";
    let dmsArray = getDMSArray(dd, hemisphere);

    return `${dmsArray[3]}${dmsArray[0]}°${dmsArray[1]}'`;
  },

	/**
	 * @param {number} dd
	 * @returns {`${string}${number}°${number}'`}
	 * @constructor
	 */
  GET_DMS_TO_LAT: (dd) => {
    let hemisphere = dd < 0 ? "S" : "N";
    let dmsArray = getDMSArray(dd, hemisphere);

    // return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
    return `${dmsArray[3]}${dmsArray[0]}°${dmsArray[1]}'`;
  },

	/**
	 * @param {DistanceUnitType} unit
	 * @param {number} lat1 - start latitude
	 * @param {number} lon1 - start longitude
	 * @param {number} lat2 - end latitude
	 * @param {number} lon2 - end longitude
	 * @returns {number}
	 */
  DISTANCE: (unit, lat1, lon1, lat2, lon2) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;


		//1.1515 is the number of statute miles in a nautical mile
		//1.609344 is the number of kilometers in a mile
		//1000 is the number of meters in a kilometer
		//60 is the number of minutes in a degree
    dist = dist * 60 * 1.1515 * 1.609344 * 1000; // meters

		return units.convertFromTrueValue(dist, unit);
  },

	/**
	 * Calculates the flight time in hours
	 * @param {SpeedUnitType} speedUnit
	 * @param {number} speed - speed
	 * @param {number} lat1 - start latitude
	 * @param {number} lon1 - start longitude
	 * @param {number} lat2 - end latitude
	 * @param {number} lon2 - end longitude
	 * @returns {number} - time in hours
	 */
  FLIGHT_TIME: (speedUnit, speed, lat1, lon1, lat2, lon2) => {
		const trueValue = units.convertToTrueValue(speed, speedUnit);
		return ROUTE_PLAN_CALCULATOR.DISTANCE("km", lat1, lon1, lat2, lon2) / trueValue;
  },

	/**
	 * @param {TimeUnitType} timeUnit
	 * @param {number} flightTime - in <timeUnit>
	 * @param {ConsumptionUnitType} avgConsumptionUnit
	 * @param {number} avgConsumption - in <avgConsumptionUnit>
	 * @returns {number}
	 */
  CONSUMPTION: (timeUnit, flightTime, avgConsumptionUnit, avgConsumption)=> {
		return units.convert(flightTime, timeUnit, "h") * units.convert(avgConsumption, avgConsumptionUnit, "lh");
  },

	/**
	 * @param {RoutePlan} routePlan
	 * @returns {number}
	 */
  TOTAL_CONSUMPTION: (routePlan) => {
    let total = routePlan.avgConsumption / 6;
		total += routePlan.routePlanElements.reduce((c, e) => c + e.consumption, 0);
    return total;
  },

	/**
	 *
	 * @param {number} lat1 - start latitude
	 * @param {number} lon1 - start longitude
	 * @param {number} lat2 - end latitude
	 * @param {number} lon2 - end longitude
	 * @returns {number}
	 */
  TRUE_TRACK: (lat1, lon1, lat2, lon2) => {

    const toRad = (Math.PI / 180.0);
    const toDeg = (180.0 / Math.PI);

    const f1 = lat1 * toRad;
    const f2 = lat2 * toRad;
    const dl = (lon2 - lon1) * toRad;
    const y = Math.sin(dl) * Math.cos(f2);
    const x = Math.cos(f1) * Math.sin(f2) - Math.sin(f1) * Math.cos(f2) * Math.cos(dl);
    const t = Math.atan2(y, x);

    return t * toDeg;
  },

	/**
	 * @param {number} trueTrack
	 * @param {number} correction
	 * @returns {number}
	 */
  MAGNETIC_TRACK: (trueTrack, correction) => {
    return trueTrack + correction;
  },
};
