<template>
	<button @click="onClick()" class="help-menu help-menu-inverted" v-if="invertedColor && shouldShow">
		<font-awesome-icon icon="question-circle" size="lg" />
	</button>
	<button @click="onClick()" class="help-menu" v-else-if="shouldShow">
		<font-awesome-icon icon="question-circle" size="lg" />
	</button>
</template>

<script>
import helpmenumanager from '@/plugins/helpmenumanager'
import HelpModal from '@/components/help/HelpModal.vue';
export default {
	name: 'HelpButton',
	props: {
		helpName: {
			type: String,
			required: false,
		},
		invertedColor: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		shouldShow() {
			return Object.keys(helpmenumanager.helpers).includes(this.helpName);
		}
	},
	methods: {
		onClick() {
			window.newEvent("HELP_MENU", {
				name: this.$props.helpName
			})
			this.$modal.show(HelpModal, {
				name: this.$props.helpName
			}, {
				height: 'auto',
				width: '900px',
				clickToClose: true,
				adaptive: true,
			}, {
			});
		},
	},
}
</script >

<style scoped>
.help-menu {
	color: white;
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.help-menu-inverted {
	color: #444;
}

.help-menu:hover {
	color: rgb(210, 210, 210);
}

.help-menu-inverted:hover {
	color: black;
}

</style>
