<template>
	<div class="bundles-con">
		<LoadingSpinner v-if="loading && !error" />
		<ServerError v-if="!loading && error" />

		<div v-if="!loading && !error && !downloaded">
			<div class="border-bottom pb-3">
				<div class="d-flex justify-content-between align-items-center mb-3">
					<h5>
						{{ $t('offlineLayers.downloadList')}}
					</h5>
					<button class="btn btn-primary" @click="download" :disabled="bundlesToDownload.length === 0">
						<font-awesome-icon icon="download"/>
						{{ $t('offlineLayers.download') }}
					</button>
				</div>
				<p v-if="bundlesToDownload.length === 0" class="text-muted">
					{{ $t('offlineLayers.downloadListInfo') }}
				</p>
				<div class="bundles">
					<OfflineLayer v-for="bundle in bundlesToDownload" v-bind:key="bundle.key" v-bind:bundle="bundle" >
						<button class="btn btn-danger" @click="removeFromDownloadList(bundle)">
							<font-awesome-icon icon="times" class="mr-0"/>
						</button>
					</OfflineLayer>
				</div>
			</div>

			<div class="mt-5 pb-3">
				<h5 class="mb-3">
					{{ $t('offlineLayers.availableBundles')}}
				</h5>
				<p v-if="bundles.length === 0" class="text-muted">
					{{ $t('offlineLayers.emptyBundles') }}
				</p>
				<div class="bundles">
					<OfflineLayer v-for="bundle in bundles" v-bind:key="bundle.key" v-bind:bundle="bundle" >
						<button @click="addToDownloadList(bundle)" class="btn btn-primary" v-if="!isDownloaded(bundle)">
							<font-awesome-icon icon="plus" class="mr-0"/>
						</button>
						<div v-else>
							<font-awesome-icon icon="check"/>
							{{ $t('offlineLayers.downloaded') }}
							<button class="btn btn-link" @click="deleteBundle(bundle)" v-if="!bundle.mandatory">
								<font-awesome-icon icon="trash" class="mr-0 text-danger"/>
							</button>
						</div>
					</OfflineLayer>
				</div>
			</div>
		</div>
		<div v-else-if="downloaded" class="d-flex justify-content-center flex-column align-items-center mt-5 gap-1">
			<font-awesome-icon icon="tasks" class="text-6xl text-success" />
			<h4 class="pt-2 mb-0 px-4 font-weight-bold ">
				{{$t('offlineLayers.downloadDone')}}
			</h4>
			<p class="m-0">
				{{$t('offlineLayers.downloadDoneInfo')}}
			</p>
			<h6 class="text-muted mt-3">
				{{$t('offlineLayers.downloadDoneInfo2')}}
			</h6>
		</div>
	</div>
</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Question from "@/components/questionnaire/Question.vue";
import ServerError from "@/components/ServerError.vue";
import OfflineLayer from "@/components/offlineLayers/OfflineLayer.vue";

export default {
	name: 'OfflineLayerManager',
	components: { ServerError, LoadingSpinner, OfflineLayer },
	data() {
		return {
			loading: false,
			error: null,
			/** @type {Bundle[]} */
			bundles: [],
			/** @type {Bundle[]} */
			bundlesToDownload: [],
			downloadedKeys: [],
			downloaded: false,
		}
	},
	watch: {

	},
	props: {
		msgs: Object
	},
	methods: {
		/** @param {Bundle} bundle */
		isDownloaded(bundle) {
			return this.downloadedKeys.includes(bundle.key);
		},
		download() {
			if(this.bundlesToDownload.length === 0) {
				return;
			}
			window.loadAppLink(`r2gflightserver://download-bundle?keys=${this.bundlesToDownload.map(b => b.key).join(';')}`);
			this.downloaded = true;
		},
		addToDownloadList(bundle) {
			this.bundlesToDownload.push(bundle);
			this.bundles = this.bundles.filter(b => b.key !== bundle.key);
			this.bundlesToDownload = this.bundlesToDownload.sort((a, b) => a.order - b.order);
		},
		removeFromDownloadList(bundle) {
			this.bundlesToDownload = this.bundlesToDownload.filter(b => b.key !== bundle.key);
			this.bundles.push(bundle);
			this.bundles = this.bundles.sort((a, b) => a.order - b.order);
		},
		async deleteBundle(bundle) {
			if(bundle.mandatory) return;
			if(!(await this.$confirm(this.$t('offlineLayers.deleteConfirm')))) {
				return;
			}
			window.loadAppLink(`r2gflightserver://remove-bundle?keys=${bundle.key}`);
			this.downloadedKeys = this.downloadedKeys.filter(k => k !== bundle.key);
		}
	},
	created() {
		if(window.platform === 'web') {
			this.$router.replace({name: 'map'});
		}
	},
	async mounted() {
		this.loading = true;
		this.downloadedKeys = JSON.parse(window.localStorage.getItem('tiles') || '[]')
		const bundles = await this.$rest.getDownloadableBundles();
		if(!bundles) {
			this.error = 'Failed to load bundles';
			this.loading = false;
			return;
		}
		this.bundles = bundles.filter(b => b.tile);
		this.loading = false;
	}
}

</script>

<style scoped>

.bundles-con {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-width: 768px;
	margin: 0 auto;
	width: 100%;
}

.bundles {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

</style>
