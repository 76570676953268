<template>
	<div class="p-3 py-4 large-modal" style="max-height: 100vh; overflow-y: auto;">
		<div class="close-btn" v-on:click="$emit('close',{})">
			<font-awesome-icon class="m-auto" icon="times" />
		</div>
		<div class="row">
			<div class="col-12 px-4 ">
				<div v-html="helpText"></div>
			</div>
		</div>
		<div class="col-12 d-flex justify-content-end mt-3">
			<div class="d-flex align-items-center">
				<div v-on:click="$emit('close',{})" class="btn btn-secondary text-center">
					<font-awesome-icon class="m-auto" icon="times" />
					{{ $t('help.menu.close') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import helpmenumanager from '@/plugins/helpmenumanager';


export default {
	name: "HelpModal",
	props: [
		"name"
	],
	data() {
		return {
			helpText: ""
		}
	},
	mounted() {
		this.helpText = this.$t(helpmenumanager.helpers[this.name]);
	}
}
</script>

<style scoped>
.close-btn {
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}
</style>
