<template>
	<div class="">

		<div class="files-con" >
			<div class="files-header">
				<div class="files-header-header">
					<div>
						<h4>{{$t('myFiles.title')}}</h4>
						<p class="m-0">
							{{$t('myFiles.desc')}}
						</p>
						<h6 class="m-0" v-if="isMobile" v-html="$t('myFiles.mobileDesc')"></h6>
					</div>
					<div class="file-progress" v-if="info && !isMobile">
						{{$t('myFiles.usage')}}: {{ info.size | formatBytes }} / {{ info.maxSize | formatBytes }}
						<ProgressBar :value="progress*100"  class="w-100" :showValue="false" />
					</div>
				</div>
				<div class="files-header-body">
					<FileUpload name="file" class="w-100"
											:customUpload="true" @uploader="uploader"
											:multiple="true" :maxFileSize="info.maxSize - info.size"
											accept="image/png, image/jpg, image/jpeg, application/pdf"
											v-if="!isMobile"
											:chooseLabel="$t('myFiles.select')"
											:uploadLabel="$t('myFiles.upload')"
											:cancelLabel="$t('myFiles.cancel')"
					/>
				</div>
			</div>
			<div class="crumbs" v-if="showCrumbs">
				<div class="crumb" @click="setPath(home.to)">
					<font-awesome-icon class="path" :icon="home.icon" />
					<font-awesome-icon class="arrow" icon="chevron-right" v-if="crumbs.length === 0" />
				</div>
				<div class="crumb" v-for="crumb in crumbs" v-bind:key="crumb.to">
					<font-awesome-icon class="arrow" icon="chevron-right" />
					<span class="path" @click="setPath(crumb.to)">{{ crumb.label }}</span>
				</div>
			</div>
			<div class="w-100">
				<LoadingSpinner v-if="loading && !error" />
				<ServerError v-if="!loading && error" />
				<div class="files" v-if="!loading && !error">
					<VFile v-for="file in files" :key="file.path" :vfile="file" :mobile="isMobile" @reload="reload" @setPath="setPath" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ServerError from "@/components/ServerError.vue";
import VFile from "@/components/myfiles/VFile.vue";
import ProgressBar from "primevue/progressbar";
import FileUpload from "primevue/fileupload";
export default {
	name: 'MyFilesView',
	components: { FileUpload, VFile, ServerError, LoadingSpinner, ProgressBar},
	data() {
		return {
			loading: false,
			error: null,
			/** @type {VirtualFile[]} */
			allFiles: [],
			/** @type {MyFileInfo} */
			info: {
				size: 0,
				maxSize: 0
			},
			path: '',
			home: {icon: 'home', to: '/'},
			mobileReloadInterval: null
		}
	},
	watch: {

	},
	computed: {
		isMobile() {
			return window.platform !== 'web';
		},
		progress() {
			if(!this.info) return 0;
			return this.info.size / this.info.maxSize;
		},

		/** @returns {VirtualFile[]} */
		files() {
			const parts = this.path.split('/').filter(p => p);
			if(parts.length === 0) {
				return this.allFiles;
			}

			let files = this.allFiles;
			let path = '';
			for (let part of parts) {
				path += '/' + part;
				files = files.filter(f => f.path.startsWith(path + "/") && f.path !== path);
			}

			return files;
		},
		crumbs() {
			const parts = this.path.split('/').filter(p => p);
			const crumbs = [];
			let path = '';
			for (let part of parts) {
				path += '/' + part;
				crumbs.push({label: part, to: path});
			}
			return crumbs;
		},
		showCrumbs() {
			return this.allFiles.some(f => f.directory);
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		async loadFromApi() {
			const [files, info] = await Promise.all([
				this.$rest.getAllMyFiles(),
				this.$rest.getMyFilesInfo(),
			]);
			this.allFiles = files.sort((a, b) => a.name.localeCompare(b.name));
			this.info = info;
		},
		loadFromMobile() {
			try{
				const filesRaw = window.localStorage.getItem('files') || '[]';
				const files = JSON.parse(filesRaw);
				this.allFiles = files.sort((a, b) => a.name.localeCompare(b.name));
			}catch (e) {
				this.error = 'Failed to load bundles';
			}
		},
		async reload() {
			this.loading = true;
			if(!this.isMobile) await this.loadFromApi();
			else this.loadFromMobile();


			if(!this.allFiles) {
				this.error = 'Failed to load bundles';
				this.loading = false;
				return;
			}
			this.loading = false;
		},
		setPath(path) {
			this.path = path;
		},
		async uploader(event) {
			this.loading = true;
			await this.$rest.uploadFiles(event.files, this.path);
			await this.reload();
		}
	},
	async mounted() {
		await this.reload();
		if(this.isMobile) {
			this.mobileReloadInterval = setInterval(this.loadFromMobile, 1000);
		}
	},
	beforeUnmount() {
		if(this.mobileReloadInterval) {
			clearInterval(this.mobileReloadInterval);
		}
	},
	created() {
		const token = this.$store.getters.loggedInUserToken;
		if (token && window.platform !== 'web') {
			window.loadAppLink('r2gflightserver://refetch-files?token=' + token);
		}
	}
}

</script>

<style scoped>

.files-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1rem 1.5rem;
	gap: 1rem;
	border-bottom: 1px solid #ccc;
}

.files-header-body {

	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
}

.files-header-header {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;

}

.files-con {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.files {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	width: 100%;
}

.file-progress {
	width: 15rem;
}
.crumbs {
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem 1rem 0rem 1rem;
	width: 100%;
	min-height: 40px;
}

.crumb {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.crumb .path {
	cursor: pointer;
	font-size: 0.9rem;
	padding: 0;
	margin: 0;
}

.crumb .path:hover {
	text-decoration: underline;
}

.crumb .arrow {
	font-size: 0.9rem;
}



</style>
